import React from 'react';
import { Header } from 'components';
import { Typography } from '@mui/material';
import { ContainerStyled, colors } from 'style';
import { FlightSearchForm } from 'components/pages/home/FlightSearchForm';

export const Home = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      Flight Search
    </Typography>
  );

  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <FlightSearchForm />
      </ContainerStyled>
    </>
  );
};
