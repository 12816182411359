import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { bookingSlice } from 'redux/slices/booking/booking-slice';
import { journeyOptionSlice } from 'redux/slices/journeyOption/journeyOption-slice';
import { paymentSlice } from 'redux/slices/payment/payment-slice';
import { searchSlice } from 'redux/slices/search/search-slice';
import { flightOrderSlice } from 'redux/slices/flightOrder/flightOrder-slice';
import { rtkQueryErrorLogger } from 'redux/middleware/restApi-middleware';
import { priceOfferSlice } from 'redux/slices/flightOffer/priceOffer-slice';
import { debuggerOptionsSlice } from './slices/debuggerOptions/debuggerOptions-slice';

const rootReducer = combineReducers({
  booking: bookingSlice.reducer,
  journeyOption: journeyOptionSlice.reducer,
  payment: paymentSlice.reducer,
  search: searchSlice.reducer,
  flightOrder: flightOrderSlice.reducer,
  priceOffer: priceOfferSlice.reducer,
  debugger: debuggerOptionsSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(rtkQueryErrorLogger),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
