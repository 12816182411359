import React from 'react';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

const mock = [
  {
    label: 'WhatsApp',
    value: '+19165532729',
    icon: (
      <Link href="https://wa.me/19165532729">
        <WhatsAppIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Email',
    value: 'info@fasax.com',
    icon: (
      <Link href="mailto:info@fasax.com">
        <AlternateEmailIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Facebook',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://www.facebook.com/fasaxtravel">
        <FacebookOutlinedIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Twitter',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://twitter.com/FasaxTravel">
        <TwitterIcon color="primary" />
      </Link>
    ),
  },
  {
    label: 'Instagram',
    value: '@fasaxtravel',
    icon: (
      <Link href="https://instagram.com/FasaxTravel">
        <InstagramIcon color="primary" />
      </Link>
    ),
  },
];

export const Contact = () => {
  const theme = useTheme();

  const LeftSide = () => {
    return (
      <Box>
        <Box marginBottom={2}>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }} gutterBottom>
            Contact us
          </Typography>
          <Typography color="text.secondary">
            If you are interested in partnering with Fasax, have questions, or just want to chat, you can reach us at:
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
          {mock.map((item, i) => (
            <Box key={i} component={ListItem} disableGutters width={'auto'} padding={0}>
              <Box component={ListItemAvatar} minWidth={'auto !important'} marginRight={2}>
                <Box component={Avatar} bgcolor={theme.palette.secondary.main} width={40} height={40}>
                  {item.icon}
                </Box>
              </Box>
              <ListItemText primary={item.label} secondary={item.value} />
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const RightSide = () => {
    return (
      <iframe
        width="100%"
        height="100%"
        frameBorder="0"
        title="map"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.3761839936915!2d44.07184362688541!3d9.56280377124748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1628bfbfe498cf1b%3A0xefa8edadc1e2f311!2sGacan%20Jilib%20St%202-34%2C%20Hargeisa!5e0!3m2!1sen!2sso!4v1662161265617!5m2!1sen!2sso"
        style={{
          minHeight: 300,
          filter: theme.palette.mode === 'dark' ? 'grayscale(0.5) opacity(0.7)' : 'none',
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Box width={1} margin={'0 auto'} paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} position={'relative'}>
          <Box width={1} order={{ xs: 2, md: 1 }}>
            <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
              <LeftSide />
            </Box>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};
