import React, { useCallback } from 'react';
import { Button, Typography } from '@mui/material';
import { colors } from 'style';
import { MarginTop } from 'components/MarginTop';
import { FLIGHT_OPTIONS_data } from 'queries/options';
import { useDispatch } from 'react-redux';
import { setDateSingleValue } from 'redux/slices/search/search-slice';
import { ButtonWrapper, EmptyOptionsContainer, EmptySearchNotification } from 'components/pages/options/FlightOptions/styles';

interface Props {
  data: FLIGHT_OPTIONS_data | null;
  goBackHandler: () => void;
}

export const EmptyOptionsResult = ({ data, goBackHandler }: Props) => {
  // Handler that will update the departure value in the store and then call goBackHandler
  const dispatch = useDispatch();
  const handleRecommendationSelection = useCallback(
    (date: string) => {
      dispatch(setDateSingleValue(date));
      goBackHandler();
    },
    [goBackHandler],
  );

  return (
    <EmptyOptionsContainer>
      <EmptySearchNotification>
        <Typography fontSize={18} lineHeight={'21px'} fontWeight={300} color={colors.black}>
          We did not find any recommendation for the selected date, please try a different date.
        </Typography>
      </EmptySearchNotification>

      {data?.outbound?.map((item: { recommendationDate: string }) => {
        return (
          <MarginTop key={item.recommendationDate} marginTop={'30'}>
            <Button
              variant={'outlined'}
              fullWidth={true}
              size={'large'}
              onClick={() => {
                handleRecommendationSelection(item.recommendationDate);
              }}
            >
              <div>{item.recommendationDate}</div>
            </Button>
          </MarginTop>
        );
      })}
      <ButtonWrapper>
        <Button fullWidth={true} variant="contained" size="large" onClick={goBackHandler}>
          back to search
        </Button>
      </ButtonWrapper>
    </EmptyOptionsContainer>
  );
};
