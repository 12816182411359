import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'style/vars/vars.css';
import { App } from 'pages/App';
import { reportWebVitals } from './reportWebVitals';

const asciiGreeting = `
  ▄████████    ▄████████    ▄████████    ▄████████  ▀████    ▐████▀ 
  ███    ███   ███    ███   ███    ███   ███    ███   ███▌   ████▀  
  ███    █▀    ███    ███   ███    █▀    ███    ███    ███  ▐███    
 ▄███▄▄▄       ███    ███   ███          ███    ███    ▀███▄███▀    
▀▀███▀▀▀     ▀███████████ ▀███████████ ▀███████████    ████▀██▄     
  ███          ███    ███          ███   ███    ███   ▐███  ▀███    
  ███          ███    ███    ▄█    ███   ███    ███  ▄███     ███▄  
  ███          ███    █▀   ▄████████▀    ███    █▀  ████       ███▄ 

  
  Welcome hacker! Thanks for taking a look at our site.
  We'd love to hear from you.

  If you're interested in:
  - Connecting to our APIs
  - Working with us
  - Saying hi
  Please email info@ and mention this banner (so we know you're 1337)
  
  If you discover a security issue, please email security@
`;

console.log(asciiGreeting);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
