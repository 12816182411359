import ListItem from '@mui/material/ListItem';
import React, { useEffect } from 'react';
import FasaxLogo from 'assets/img/fasax.png';
import { DrawerStyled, ListHeaderItem, ListTextItem, LogoWrapper } from './styles';
import { List, ListItemText } from '@mui/material';
import { useHistory } from 'react-router-dom';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import { routes } from 'routes';
import { DrawerPage } from 'enum/enum';
import { useDispatch } from 'react-redux';
import { resetSearchState } from 'redux/slices/search/search-slice';
import { Debugger } from '../Debugger';
import { setDebuggerMode } from '../../redux/slices/debuggerOptions/debuggerOptions-slice';

type PropsType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const Drawer = ({ isOpen, setIsOpen }: PropsType) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const isShowDebugger = JSON.parse(process.env.REACT_APP_DEBUG_MODE || 'false');
    dispatch(setDebuggerMode(isShowDebugger));
  }, []);

  const clearValues = () => {
    dispatch(resetSearchState());
    history.push(routes.home);
  };

  const navigateToPage = (redirectUrl?: DrawerPage) => {
    if (redirectUrl) {
      switch (redirectUrl) {
        case DrawerPage.NEW_SEARCH: {
          return history.push(routes.home);
        }
        case DrawerPage.ABOUT: {
          return history.push(routes.about);
        }
        case DrawerPage.PRIVACY_TERMS: {
          return history.push(routes.privacyTerms);
        }
        case DrawerPage.HELP: {
          return history.push(routes.help);
        }
        case DrawerPage.MY_TRIPS: {
          return history.push(routes.mytrips);
        }
        case DrawerPage.BLOG: {
          return history.push(routes.blog);
        }
        default:
          return routes.home;
      }
    }
  };

  const closeDrawer = (redirectUrl?: DrawerPage) => {
    if (redirectUrl === DrawerPage.NEW_SEARCH) {
      clearValues();
    }
    navigateToPage(redirectUrl);
    setIsOpen(false);
  };

  return (
    <DrawerStyled anchor="left" open={isOpen} onClose={() => setIsOpen(false)}>
      <LogoWrapper>
        <img src={FasaxLogo} alt={'logo'} />
      </LogoWrapper>
      <List>
        <ListHeaderItem>
          <ListItemText primary={'Search'} />
        </ListHeaderItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.NEW_SEARCH)}>
          <FlightTakeoffIcon /> <ListTextItem primary={'Start New Search'} />
        </ListItem>
        <ListHeaderItem>
          <ListItemText primary={'Plan your travel'} />
        </ListHeaderItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.MY_TRIPS)}>
          <WorkTwoToneIcon /> <ListTextItem primary={'My Trips'} />
        </ListItem>
        <ListHeaderItem>
          <ListItemText primary={'Company'} />
        </ListHeaderItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.HELP)}>
          <HelpOutlineIcon /> <ListTextItem primary={'Help/FAQ'} />
        </ListItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.ABOUT)}>
          <InfoIcon /> <ListTextItem primary={'About'} />
        </ListItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.PRIVACY_TERMS)}>
          <PrivacyTipIcon />
          <ListTextItem primary={'Privacy & Terms'} />
        </ListItem>
        <ListItem button onClick={() => closeDrawer(DrawerPage.BLOG)}>
          <NewspaperIcon />
          <ListTextItem primary={'Blog'} />
        </ListItem>
        <Debugger />
      </List>
    </DrawerStyled>
  );
};
