import * as yup from 'yup';
import { errorMessages } from './errorMessages';
import { regex } from 'validation/regex';
import 'yup-phone';
export const validation = {
  email: yup.string().email(errorMessages.email).required(errorMessages.required),
  emailNotRequired: yup.string().email(errorMessages.email).notRequired(),
  boolean: yup.boolean(),
  number: yup.number().typeError(errorMessages.number).required(errorMessages.required),
  stringNotRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .notRequired(),
  string: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required),

  stringWithoutNumbers: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .matches(regex.includesNumbers, errorMessages.shouldNotContainerNumbers),

  stringWithoutNumbersNotRequired: yup
    .string()
    .trim()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .matches(regex.includesNumbers, errorMessages.shouldNotContainerNumbers)
    .notRequired(),
  date: yup
    .date()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required),

  dateNotRequired: yup
    .date()
    .transform((curr, orig) => (orig === '' ? undefined : curr))
    .required(errorMessages.required)
    .nullable()
    .notRequired(),

  phoneNumber: (countryCode: string | undefined) =>
    yup.string().phone(countryCode, false, errorMessages.phoneInvalid).required(errorMessages.phoneRequired),
};
