import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isOrchestra: boolean;
  isAmadeus: boolean;
  isDebug: boolean;
}
const initialState: InitialState = {
  isOrchestra: false,
  isAmadeus: false,
  isDebug: false,
};

export const debuggerOptionsSlice = createSlice({
  name: 'optionsSlice',
  initialState,
  reducers: {
    setOrchestraChecked(state, action: PayloadAction<boolean>) {
      state.isOrchestra = action.payload;
    },
    setAmadeusChecked(state, action: PayloadAction<boolean>) {
      state.isAmadeus = action.payload;
    },
    setDebuggerMode(state, action: PayloadAction<boolean>) {
      state.isDebug = action.payload;
    },
  },
});
export const { setAmadeusChecked, setOrchestraChecked, setDebuggerMode } = debuggerOptionsSlice.actions;
