import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { AutocompleteRenderInputParams, createFilterOptions, Stack, TextField } from '@mui/material';
import { AutocompleteStyled } from './styles';
import { DirectionEnum } from 'enum/enum';
import { airports } from 'data/airports';
import { Control, Controller, FieldError } from 'react-hook-form';
import { AirportInfoModel } from 'models/airport.model';

type PropsType = {
  control: Control<any>;
  name: string;
  direction: DirectionEnum;
  inputLogo: ReactElement;
  placeholderText: string;
  error?: FieldError;
};

export const AutocompleteAirport = ({ inputLogo, placeholderText, control, name, error }: PropsType) => {
  const [inputValue, setInputValue] = useState<string | null>('');
  const [open, setOpen] = useState<boolean>(false);

  const getOptionLabel = (option: AirportInfoModel) => {
    if (option.airportCode && option.name) {
      return `${option.airportCode} - ${option.city}`;
    }
    return '';
  };

  const onOpenHandler = () => {
    if (inputValue) {
      setOpen(true);
    }
  };

  const onChangeInputHandler = (event: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => JSON.stringify(option),
  });

  useEffect(() => {
    if (!inputValue) {
      setOpen(false);
    }
  }, [inputValue]);

  return (
    <Stack>
      <Controller
        defaultValue={null}
        control={control}
        name={name}
        render={({ field: { value: autocompleteValue, onChange, ...rest } }) => {
          return (
            <AutocompleteStyled
              error={!!error}
              id={`AirportId-${placeholderText}-${autocompleteValue}`}
              open={open}
              noOptionsText={'No Matching Airport'}
              onOpen={onOpenHandler}
              value={autocompleteValue}
              onChange={(e: React.SyntheticEvent, v: AirportInfoModel) => {
                onChange(v);
              }}
              inputValue={inputValue?.trim()}
              filterOptions={filterOptions}
              onInputChange={onChangeInputHandler}
              onClose={() => setOpen(false)}
              options={airports}
              getOptionLabel={getOptionLabel}
              isOptionEqualToValue={(option: AirportInfoModel, value: AirportInfoModel) => option.airportCode === value.airportCode}
              renderOption={(props: AutocompleteRenderInputParams, option: AirportInfoModel) => (
                <li {...props}>
                  {option.airportCode} - {option.name},{option.city}
                </li>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  value={autocompleteValue ? `${autocompleteValue.airportCode} -${autocompleteValue.city}` : ''}
                  variant="filled"
                  placeholder={!autocompleteValue ? placeholderText : ''}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: inputLogo,
                  }}
                />
              )}
              {...rest}
            />
          );
        }}
      />
    </Stack>
  );
};
