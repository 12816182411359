import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JourneyOfferModel } from 'models/journeyOption.model';

interface InitialState {
  priceOffer: JourneyOfferModel | null;
}
const initialState: InitialState = {
  priceOffer: null,
};

export const priceOfferSlice = createSlice({
  name: 'flightOffer',
  initialState,
  reducers: {
    setPriceOffer(state, action: PayloadAction<JourneyOfferModel>) {
      state.priceOffer = action.payload;
    },
    resetPriceOfferState(state) {
      state = initialState;
    },
  },
});
export const { setPriceOffer, resetPriceOfferState } = priceOfferSlice.actions;
