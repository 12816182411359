import React, { useEffect, useState } from 'react';
import { ContainerStyled } from 'style';
import { ChargeBoard } from 'components/pages/entry/ChargeBoard/ChargeBoard';
import { PaymentTabs } from 'components/pages/entry/PaymentTabs/PaymentTabs';
import { EnterPaymentForm } from './forms/EnterPaymentForm';
import { CompanyPictures } from 'components/pages/entry/CompanyPictures/CompanyPictures';
import zaadLogo from 'assets/img/zaad.jpg';
import evcplusLogo from 'assets/img/evcplus.png';
import golisLogo from 'assets/img/golis.png';
import { useAppSelector } from 'hooks/useAppSelector';
import { Currency, PaymentMode, PaymentNetwork, PaymentStatusEnum } from 'enum/enum';
import { useMutation } from '@apollo/client';
import { CREATE_PAYMENT, CREATE_PAYMENT_data, CREATE_PAYMENT_vars } from 'mutations/createPayment';
import { useDispatch } from 'react-redux';
import { setPaymentStripe } from 'redux/slices/payment/payment-slice';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';

const tabs = [
  { network: PaymentNetwork.EVC_PLUS, img: evcplusLogo, paymentMode: PaymentMode.WAAFI },
  { network: PaymentNetwork.ZAAD, img: zaadLogo, paymentMode: PaymentMode.WAAFI },
  { network: PaymentNetwork.SAHAL, img: golisLogo, paymentMode: PaymentMode.WAAFI },
  { network: PaymentNetwork.CREDIT_CARD, img: zaadLogo, paymentMode: PaymentMode.STRIPE },
];

export const PaymentEntry = () => {
  const [activePaymentTab, setActivePaymentTab] = useState(tabs[0]);
  const [contactNumber, setContactNumber] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();

  const values = useAppSelector((state) => state.search);
  const { journeyOption } = useAppSelector((state) => state.journeyOption);
  const priceTotal = journeyOption?.priceTotal;
  const flightOrderId = useAppSelector((state) => state.flightOrder.flightOrderId);
  const price = `${priceTotal}` || '';

  const [createIntentPayment, { loading }] = useMutation<CREATE_PAYMENT_data, CREATE_PAYMENT_vars>(CREATE_PAYMENT);

  const initialPaymentRequest = async () => {
    const paymentData = await createIntentPayment({
      variables: {
        flightOrderId: flightOrderId as string,
        paymentMode: PaymentMode.STRIPE,
        currencyString: Currency.USD,
        accountNumber: contactNumber?.replaceAll('+', ''),
        amountString: price,
      },
    });
    if (paymentData.data?.createPayment) {
      dispatch(setPaymentStripe(paymentData.data.createPayment));
    }
  };

  useEffect(() => {
    initialPaymentRequest();
  }, []);

  const onSubmit = async () => {
    const paymentData = await createIntentPayment({
      variables: {
        flightOrderId: flightOrderId as string,
        amountString: price,
        currencyString: Currency.USD,
        ...(activePaymentTab.paymentMode !== PaymentMode.STRIPE && { accountNumber: contactNumber?.replaceAll('+', '') }),
        paymentMode: activePaymentTab.paymentMode,
      },
    });
    if (paymentData.data?.createPayment.paymentState === PaymentStatusEnum.OPEN) {
      history.push(routes.approval);
    }
  };

  return (
    <ContainerStyled>
      <ChargeBoard priceTotal={priceTotal as number} values={values} />
      <PaymentTabs tabs={tabs} active={activePaymentTab} setActive={setActivePaymentTab} disabled={loading} />
      <EnterPaymentForm
        network={activePaymentTab.network}
        paymentLogo={activePaymentTab.img}
        onSubmit={onSubmit}
        setContactNumber={setContactNumber}
      />
      <CompanyPictures />
    </ContainerStyled>
  );
};
