import { colors } from 'style';
import styled from '@emotion/styled';

export const Placeholder = styled('span')`
  color: ${colors.placeholder};
`;

export const DateBirthdayBlock = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 12px;
  grid-template-areas: '. . .';
  margin-top: 12px;
`;
