import React, { useEffect, useMemo, useState } from 'react';
import { colors, ContainerStyled, JustifyCenter } from 'style';
import { Typography } from '@mui/material';
import { Timer } from 'components/pages/approval/Timer/Timer';
import { Card, MarginTop } from 'components';
import { PhoneNumberIcon } from 'assets/icons/phoneNumberIcon';
import { useWindowSize } from 'hooks/useWindowWidth';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { PaymentStatusEnum } from 'enum/enum';
import { resetPaymentState, setPaymentState } from 'redux/slices/payment/payment-slice';
import { useLazyQuery } from '@apollo/client';
import { FLIGHT_ORDER_DETAILS } from 'queries/flightOrderDetails';

export const PaymentApprovalPage = () => {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { height } = windowSize;
  const isSmallHeight = height <= 720;

  const [seconds, setSeconds] = useState(45);
  const paymentState = useAppSelector((state) => state.payment?.paymentStripe?.paymentState);

  const { flightOrderId } = useAppSelector((state) => state.flightOrder);
  const isTimeOut = seconds === 0;
  const paymentStatusSuccessList = [PaymentStatusEnum.AUTHORIZED, PaymentStatusEnum.PAID];
  const paymentStatusFailureList = [PaymentStatusEnum.CANCELLED, PaymentStatusEnum.FAILED, PaymentStatusEnum.ERROR];

  const isPaymentStatusSucceed = useMemo(() => paymentStatusSuccessList.includes(paymentState as PaymentStatusEnum), [paymentState]);
  const isPaymentStatusFailed = useMemo(() => paymentStatusFailureList.includes(paymentState as PaymentStatusEnum), [paymentState]);
  const [getFlightOrderDetails] = useLazyQuery(FLIGHT_ORDER_DETAILS, {
    variables: {
      flightOrderId,
    },
    fetchPolicy: 'no-cache',
  });
  const changePaymentNumber = () => {
    dispatch(resetPaymentState());
    history.push(routes.entry);
  };

  const pingPaymentStatus = async () => {
    const { data } = await getFlightOrderDetails();
    const responseStatus = data?.flightOrderDetails.associatedPayments[data?.flightOrderDetails.associatedPayments.length - 1].paymentState;
    dispatch(setPaymentState(responseStatus));
  };

  const retryRequestHandler = async () => {
    dispatch(setPaymentState(PaymentStatusEnum.OPEN));
    setSeconds(45);
  };

  useEffect(() => {
    const intervalId = setInterval(pingPaymentStatus, 4000);
    if (isPaymentStatusFailed || isTimeOut) {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [paymentState, isPaymentStatusFailed, isTimeOut]);

  useEffect(() => {
    if (isPaymentStatusSucceed) {
      return history.push(routes.success);
    }
  }, [paymentState]);

  return (
    <ContainerStyled>
      <JustifyCenter>
        <MarginTop marginTop={isSmallHeight ? '0' : '32'}>
          <Typography fontSize={22} lineHeight={'24px'} fontWeight={500} color={colors.primary}>
            Time is of the essence...
          </Typography>
        </MarginTop>
      </JustifyCenter>
      <MarginTop marginTop={'36'}>
        {isPaymentStatusFailed && (
          <Card
            title={'Technical Error'}
            firstButtonTitle={'retry'}
            isTwoButtons={true}
            secondButtonTitle={'change number'}
            subtitle={'There was a technical error. Please retry the payment'}
            firstButtonFc={retryRequestHandler}
            secondButtonFc={changePaymentNumber}
          />
        )}
        {isTimeOut && (
          <Card
            firstButtonTitle={'retry'}
            isTwoButtons={true}
            secondButtonTitle={'change number'}
            title={'Out of Time'}
            subtitle={'The charge attempt has expired would you like to retry or change the phone number? '}
            firstButtonFc={retryRequestHandler}
            secondButtonFc={changePaymentNumber}
          />
        )}
      </MarginTop>

      {!isTimeOut && !isPaymentStatusFailed && <Timer setSeconds={setSeconds} seconds={seconds} />}
      <JustifyCenter>
        <MarginTop marginTop={isSmallHeight ? '24' : '80'}>
          <PhoneNumberIcon />
        </MarginTop>
      </JustifyCenter>
    </ContainerStyled>
  );
};
