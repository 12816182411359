import React, { SetStateAction } from 'react';
import { ButtonTab, TabsWrapper } from 'components/pages/entry/PaymentTabs/styles';
import { MarginTop } from 'components';
import { Typography } from '@mui/material';
import { PaymentMode, PaymentNetwork } from 'enum/enum';

interface ITab {
  network: PaymentNetwork;
  img: string;
  paymentMode: PaymentMode;
}

interface IProps {
  tabs: ITab[];
  setActive: React.Dispatch<SetStateAction<ITab>>;
  active: ITab;
  disabled?: boolean;
}

export const PaymentTabs = ({ tabs, setActive, active, disabled }: IProps) => {
  const onClickHandler = (currentTab: ITab) => {
    setActive(currentTab);
  };

  return (
    <>
      <MarginTop marginTop={'16'}>
        <Typography fontSize={20} lineHeight={'24px'} fontWeight={500}>
          Ku Bixi
        </Typography>
      </MarginTop>
      <MarginTop marginTop={'20'}>
        <TabsWrapper>
          {tabs.map((tab, i) => {
            return (
              <ButtonTab disabled={disabled} key={tab.network} active={tabs[i].network === active.network} onClick={() => onClickHandler(tabs[i])}>
                {tabs[i].network}
              </ButtonTab>
            );
          })}
        </TabsWrapper>
      </MarginTop>
    </>
  );
};
