import React, { useEffect, useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { PassengersCountDrawer } from 'components/pages/home/PassengersCountDrawer';
import { BoxSpaceBetween, ButtonBlock } from 'style';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DirectionEnum } from 'enum/enum';
import {
  AddFlightBlock,
  AddFlightButton,
  ChangeWayButton,
  Form,
  SearchButton,
  SelectionDateBlock,
} from 'components/pages/home/FlightSearchForm/style';
import { MarginTop } from 'components';
import { AutocompleteAirport } from 'components/pages/home/AutocompleteAirport';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SelectHome } from 'components/pages/home/SelectHome';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { SelectionDates } from 'components/pages/home/SelectionDate/SelectionDate';
import { convertShortDate } from 'utils/dates';
import { directionOptions, ticketClassOptions } from 'components/pages/home/FlightSearchForm/constans';
import { schema } from 'components/pages/home/FlightSearchForm/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { setAmadeus, setOrchestra, setSearchFormValues } from 'redux/slices/search/search-slice';
import { PassengerCountModel } from 'models/passenger.model';
import { useAppSelector } from 'hooks/useAppSelector';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { SearchModel } from 'models/search.model';
import { routes } from 'routes';

export const FlightSearchForm = () => {
  const search = useAppSelector((state) => state.search);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<SearchModel>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: search,
  });

  watch(['direction', 'ItineraryRequest']);

  const direction = getValues('direction');
  const isRound = direction === DirectionEnum.ROUND;
  const isMultiCity = direction === DirectionEnum.MULTI;

  const { isDebug, isOrchestra, isAmadeus } = useAppSelector((state) => state.debugger);

  const filteredDirectionOptions = useMemo(() => {
    if (!isDebug) {
      return directionOptions.filter((item) => item.value !== DirectionEnum.MULTI);
    }
    return directionOptions;
  }, [isDebug]);

  const {
    fields: itineraryRequest,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'ItineraryRequest',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isDebug) {
      dispatch(setOrchestra(isOrchestra));
      dispatch(setAmadeus(isAmadeus));
    }
  }, []);

  const resetReturnDateForNotRound = () => {
    const itineraryLength = getValues('ItineraryRequest').length;
    if (!isRound) {
      for (let i = 0; i < itineraryLength; i++) {
        setValue(`ItineraryRequest.${i}.returnDate`, null);
      }
    }
  };
  const onSubmit = (values: SearchModel) => {
    if (values.direction === DirectionEnum.ROUND) {
      const firstFlight = values.ItineraryRequest[0];
      const backFlight = {
        departureDate: firstFlight.returnDate,
        originAirport: firstFlight?.destinationAirport,
        destinationAirport: firstFlight?.originAirport,
        returnDate: firstFlight.returnDate, // this is mocked data which does not do anything but avoid the validation
        includeDaysAfter: 0,
        includeDaysBefore: 0,
      };
      const valuesForRoundTrip = { ...values, ItineraryRequest: [firstFlight, backFlight] };
      dispatch(setSearchFormValues(valuesForRoundTrip));
      history.push(routes.options);
    } else {
      resetReturnDateForNotRound();
      dispatch(setSearchFormValues(values));
      history.push(routes.options);
    }
  };

  const handlePassengers = (updatedPassengers: PassengerCountModel) => {
    setValue('passengers', updatedPassengers);
  };

  const handleChangeWays = (index: number) => {
    const currentItinerary = getValues(`ItineraryRequest.${index}`);
    if (!currentItinerary.originAirport && !currentItinerary.destinationAirport) return;
    const temp = { ...currentItinerary };
    const temp2 = { ...currentItinerary };
    temp2.originAirport = temp.destinationAirport;
    temp2.destinationAirport = temp.originAirport;
    setValue(`ItineraryRequest.${index}.destinationAirport`, temp2.destinationAirport);
    setValue(`ItineraryRequest.${index}.originAirport`, temp2.originAirport);

    if (errors?.ItineraryRequest?.[index]?.originAirport && errors?.ItineraryRequest?.[index]?.destinationAirport) {
      return;
    }

    if (errors?.ItineraryRequest?.[index]?.originAirport) {
      setError(`ItineraryRequest.${index}.destinationAirport`, errors?.ItineraryRequest?.[index]?.originAirport as ErrorOption);
      clearErrors(`ItineraryRequest.${index}.originAirport`);
      return;
    }
    if (errors?.ItineraryRequest?.[index]?.destinationAirport) {
      setError(`ItineraryRequest.${index}.originAirport`, errors?.ItineraryRequest?.[index]?.destinationAirport as ErrorOption);
      clearErrors(`ItineraryRequest.${index}.destinationAirport`);
      return;
    }
  };

  const addAnotherItinerary = () => {
    const prevItinerary = getValues(`ItineraryRequest.${itineraryRequest.length - 1}`);

    append({
      departureDate: null,
      originAirport: prevItinerary?.destinationAirport,
      destinationAirport: null,
      includeDaysAfter: 0,
      includeDaysBefore: 0,
    });
  };

  const onDirectionChange = () => {
    const ItineraryRequest = getValues('ItineraryRequest');
    const updatedItineraries = ItineraryRequest.slice(0, 1);
    setValue('ItineraryRequest', updatedItineraries);
  };

  const getFormattedDateSelection = (departureDate: Date | null, returnDate?: Date | null) => {
    let formattedInputDate = '';
    if (departureDate && isRound) {
      const _returnDate = returnDate ? `- ${convertShortDate(returnDate)}` : '';
      formattedInputDate = `${convertShortDate(departureDate)} ${_returnDate}`;
    }
    if (departureDate && !isRound) {
      formattedInputDate = convertShortDate(departureDate);
    }
    return formattedInputDate;
  };
  const itineraries = isMultiCity ? itineraryRequest : itineraryRequest.slice(0, 1);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <BoxSpaceBetween>
        <SelectHome
          control={control}
          name={'direction'}
          error={errors?.direction}
          options={filteredDirectionOptions}
          onChangeSelect={onDirectionChange}
        />
        <SelectHome control={control} name={'cabin'} error={errors?.cabin} options={ticketClassOptions} />
      </BoxSpaceBetween>
      {itineraries.map((leg, index) => {
        const departureDate = getValues(`ItineraryRequest.${index}.departureDate`);
        const returnDate = getValues(`ItineraryRequest.${index}.returnDate`);
        const departureAirport = getValues(`ItineraryRequest.${index}.originAirport.airportCode`);
        const arrivalAirport = getValues(`ItineraryRequest.${index}.destinationAirport.airportCode`);

        const dateInputFormattedValue = getFormattedDateSelection(departureDate, returnDate);
        return (
          <React.Fragment key={leg.id}>
            <Box sx={{ position: 'relative' }}>
              <MarginTop marginTop={index === 0 ? '10' : '30'}>
                <AutocompleteAirport
                  error={errors?.ItineraryRequest?.[index]?.originAirport as FieldError}
                  control={control}
                  name={`ItineraryRequest.${index}.originAirport`}
                  direction={direction}
                  inputLogo={<FlightTakeoffIcon />}
                  placeholderText={'From'}
                />
              </MarginTop>
              <ChangeWayButton id={`${index}`} onClick={() => handleChangeWays(index)}>
                <CompareArrowsIcon id={`Flight-Compare-id${index}`} />
              </ChangeWayButton>
              <MarginTop marginTop="10">
                <AutocompleteAirport
                  error={errors?.ItineraryRequest?.[index]?.destinationAirport as FieldError}
                  control={control}
                  name={`ItineraryRequest.${index}.destinationAirport`}
                  direction={direction}
                  inputLogo={<FlightLandIcon />}
                  placeholderText={'To'}
                />
              </MarginTop>
            </Box>
            <MarginTop marginTop="10">
              <SelectionDateBlock>
                <SelectionDates
                  returnDate={getValues(`ItineraryRequest.${index}.returnDate`)}
                  index={index}
                  setValue={setValue}
                  error={!!errors?.ItineraryRequest?.[index]?.departureDate?.message || !!errors?.ItineraryRequest?.[index]?.returnDate?.message}
                  inputValue={dateInputFormattedValue}
                  direction={direction}
                  control={control}
                  name={`ItineraryRequest.${index}.departureDate`}
                  clearErrors={clearErrors}
                  departureAirport={departureAirport}
                  arrivalAirport={arrivalAirport}
                />
                {isMultiCity && index > 0 && (
                  <IconButton>
                    <DeleteForeverIcon id={`${index}`} color="primary" fontSize="large" onClick={() => remove(index)} />
                  </IconButton>
                )}
              </SelectionDateBlock>
            </MarginTop>

            {isMultiCity && index + 1 === itineraryRequest.length && itineraryRequest.length < 3 && (
              <AddFlightBlock>
                <AddFlightButton
                  size={'large'}
                  disabled={itineraryRequest.length > 2}
                  onClick={addAnotherItinerary}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add another flight
                </AddFlightButton>
              </AddFlightBlock>
            )}
          </React.Fragment>
        );
      })}
      <MarginTop marginTop={'10'}>
        <PassengersCountDrawer handlePassengers={handlePassengers} />
      </MarginTop>
      <ButtonBlock>
        <SearchButton id="Search Flight" fullWidth={true} variant={'contained'} size={'large'} type={'submit'}>
          Search
        </SearchButton>
      </ButtonBlock>
    </Form>
  );
};
