import { Box, styled, Typography } from '@mui/material';
import { colors } from 'style';

export const ItemRow = styled(Box)<{ marginBottom?: string; marginTop?: string; position?: string }>`
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  position: ${(props) => props.position && props.position};
  &:first-of-type {
    padding-top: 24px;
  }
  &:last-child {
    padding-bottom: 24px;
  }
`;

export const DotMarker = styled('span')`
  width: max-content;
  display: flex;
  align-items: start;
  height: max-content;
  &:after {
    content: ' \\2022';
    margin: 0 4px;
  }
`;

export const AirlineLogo = styled(Box)`
  & img {
    width: 35px;
    height: 40px;
  }
`;

export const DurationFlightBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: calc(100% - 24px);
  justify-content: end;
  border-right: 0.5px dashed ${colors.black};
  padding: 12px 12px 12px 48px;
`;

export const DurationItem = styled(Box)`
  display: flex;
  justify-content: end;

  & .MuiTypography-root {
    &:first-of-type {
      margin-right: 30px;
      width: 30%;
      display: flex;
      justify-content: flex-end;
    }
    &:last-of-type {
      flex-shrink: 0;
      display: flex;
      align-items: center;
    }
    @media (max-width: 768px) {
      &:first-of-type {
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
`;

export const TypographyTime = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  min-width: max-content;
  @media (max-width: 360px) {
    font-size: 16px;
  }
`;

export const TypographyCode = styled(Typography)`
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  @media (max-width: 340px) {
    font-size: 20px;
  }
`;
