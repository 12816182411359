import React from 'react';
import { BoardContent, BoardHeader, ChargeBordStyled, Dashes } from 'components/pages/entry/ChargeBoard/styles';
import { FlexColumn, FlexRow } from 'style';
import { DirectionEnum } from 'enum/enum';
import PersonIcon from '@mui/icons-material/Person';
import { convertShortDate } from 'utils/dates';
import { countObjItems } from 'utils/math';
import { Typography } from '@mui/material';
import { SearchModel } from 'models/search.model';

type PropsType = {
  priceTotal: number | null;
  values: SearchModel;
};

export const ChargeBoard = ({ priceTotal, values }: PropsType) => {
  const { ItineraryRequest, passengers, direction, cabin: ticketClass } = values;
  const passengersCount = countObjItems(passengers);

  return (
    <ChargeBordStyled>
      <BoardHeader>
        <Typography fontSize={22} lineHeight={'24px'} fontWeight={400}>
          Total Charge:
        </Typography>
        <Typography fontSize={22} lineHeight={'24px'} fontWeight={300} sx={{ ml: 3 }}>
          ${priceTotal}
        </Typography>
      </BoardHeader>
      <BoardContent>
        <FlexColumn>
          <FlexRow>
            <Typography fontSize={22} lineHeight={'24px'} fontWeight={500}>
              {ItineraryRequest[0].originAirport?.airportCode}
            </Typography>
            <Typography fontSize={22} lineHeight={'24px'} fontWeight={500}>
              <Dashes component={'span'}>{'- -'}</Dashes>
            </Typography>
            <Typography fontSize={22} lineHeight={'24px'} fontWeight={500}>
              {ItineraryRequest[0].destinationAirport?.airportCode}
            </Typography>
          </FlexRow>
          {direction === DirectionEnum.ONE_WAY ? (
            <FlexRow>
              <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                {convertShortDate(ItineraryRequest[0].departureDate)}{' '}
              </Typography>
            </FlexRow>
          ) : (
            <FlexRow>
              <FlexRow>
                <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                  {convertShortDate(ItineraryRequest[0].departureDate)}
                </Typography>
                <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                  <Dashes component={'span'}>{'-'}</Dashes>
                </Typography>
                <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                  {convertShortDate(ItineraryRequest[1].departureDate)}
                </Typography>
              </FlexRow>
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn>
          <FlexRow alignSelf={'end'}>
            <PersonIcon />
            {passengersCount}
          </FlexRow>
          <FlexRow>
            <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
              {ticketClass}
            </Typography>
          </FlexRow>
        </FlexColumn>
      </BoardContent>
    </ChargeBordStyled>
  );
};
