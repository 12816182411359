import { createSlice } from '@reduxjs/toolkit';
import { DirectionEnum, TicketClassEnum } from 'enum/enum';
import { SearchModel } from 'models/search.model';
import moment from 'moment';

const initialState: SearchModel = {
  ItineraryRequest: [
    {
      departureDate: null,
      returnDate: null,
      originAirport: null,
      destinationAirport: null,
      includeDaysAfter: 0,
      includeDaysBefore: 0,
    },
  ],
  passengers: { adults: 1, children: 0, infants: 0 },
  direction: DirectionEnum.ONE_WAY,
  cabin: TicketClassEnum.ECONOMY,
  includeAmadeus: false,
  includeOrchestra: false,
  includeMultiticket: false,
  ndcProvider: 'SWITCHALLINONE',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setDateSingleValue(state, action) {
      state.ItineraryRequest = state.ItineraryRequest.map((obj: any, index: number) => {
        if (index === 0) {
          return { ...obj, departureDate: action.payload };
        }
        return obj;
      });
    },
    setSearchFormValues(state, action) {
      const itineraries = action.payload.ItineraryRequest.map((i: any) => {
        return { ...i, departureDate: moment(i.departureDate) };
      });
      state.ItineraryRequest = itineraries;
      state.passengers = action.payload.passengers;
      state.direction = action.payload.direction;
      state.cabin = action.payload.cabin;
      state.includeMultiticket = action.payload.includeMultiticket;
      state.ndcProvider = action.payload.ndcProvider;
    },

    setItineraryRequest(state, action) {
      state.ItineraryRequest = action.payload;
    },
    resetSearchState(state) {
      state.ItineraryRequest = [
        {
          departureDate: null,
          returnDate: null,
          originAirport: null,
          destinationAirport: null,
          includeDaysAfter: 0,
          includeDaysBefore: 0,
        },
      ];
    },
    setOrchestra(state, action) {
      state.includeOrchestra = action.payload;
    },
    setAmadeus(state, action) {
      state.includeAmadeus = action.payload;
    },
  },
});
export const { setDateSingleValue, setSearchFormValues, resetSearchState, setOrchestra, setAmadeus } = searchSlice.actions;
