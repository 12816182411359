import moment from 'moment';
import { DateType } from 'models/date.model';

export const getTimeDifference = (first: Date, second: Date): string => {
  const start = moment(first);
  const end = moment(second);
  const h = end.diff(start, 'hours');
  const m = (end.diff(start, 'minutes') - 60 * h).toString();
  return `${h}h ${m.length === 1 ? ` 0${m}` : m}m`;
};

export const convertShortDate = (date: any): string => {
  return moment(date).isValid() ? moment(date).format('MMM DD') : '';
};
export const convertDayShortDate = (date: DateType): string => {
  return moment(date).format('ddd, MMM DD');
};

export const convertFullDate = (date: any): string => {
  return moment(date).format('YYYY-MM-DD');
};

export const convertHoursMinutes = (date: DateType): string => {
  return moment(date).format('h:mm A');
};
