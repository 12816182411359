import { Alert, Box, styled } from '@mui/material';
import { headerFooterHeight } from 'style/vars/constants';
import { colors } from 'style';

export const LoaderWrapper = styled('div')`
  height: calc(100vh - ${headerFooterHeight + 40}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyOptionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - ${headerFooterHeight + 40}px);
`;

export const EmptySearchNotification = styled(Alert)(() => ({
  backgroundColor: colors.primaryBgTransparent,
  '& .MuiSvgIcon-root': {
    fill: colors.primary,
  },
}));
EmptySearchNotification.defaultProps = {
  severity: 'info',
};

export const ButtonWrapper = styled(Box)(() => ({
  margin: '0 64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
