import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentModel } from 'models/payment.model';
import { CreatePaymentStripeDto } from 'redux/slices/payment/payment.dto';

interface IInitialState {
  paymentStripe: null | CreatePaymentStripeDto;
  paymentPassengerInfo: PaymentModel | null;
}

const initialState: IInitialState = {
  paymentStripe: null,
  paymentPassengerInfo: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentStripe(state, action) {
      state.paymentStripe = action.payload;
    },
    setPaymentPassengerInfo(state, action: PayloadAction<PaymentModel | null>) {
      state.paymentPassengerInfo = action.payload;
    },
    setPaymentState(state, action) {
      if (state.paymentStripe) {
        state.paymentStripe.paymentState = action.payload;
      }
    },
    resetPaymentState(state) {
      state = initialState;
    },
  },
});
export const { setPaymentStripe, setPaymentPassengerInfo, setPaymentState, resetPaymentState } = paymentSlice.actions;
