import React from 'react';
import { ContainerStyled } from 'style';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Hero } from './Hero';
import { Story } from './Story';
import { WhoWeAre } from './WhoWeAre';
import { Team } from './Team';
import { Partners } from './Partners';
import { Contact } from './Contact';

export const AboutPageComponents = () => {
  return (
    <ContainerStyled>
      <Hero />
      <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
        <Story />
      </Box>
      <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }} paddingTop={'0 !important'}>
        <WhoWeAre />
      </Box>
      <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={'0 !important'}>
        <Divider />
      </Box>
      <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
        <Team />
      </Box>
      <Box bgcolor={'alternate.main'}>
        <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
          <Partners />
        </Box>
      </Box>
      <Contact />
    </ContainerStyled>
  );
};
