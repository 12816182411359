import React from 'react';
import { Header } from 'components';
import { FlightOptions } from 'components/pages/options/FlightOptions';
import { ContainerStyled, FlexRow, colors } from 'style';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowRightIcon } from 'assets/icons/ArrowRightIcon';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import { countObjItems } from 'utils/math';
import { useHistory } from 'react-router-dom';
import { DirectionEnum } from 'enum/enum';
import { convertShortDate } from 'utils/dates';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';

export const FlightOptionsPage = () => {
  const reduxValues = useAppSelector((state) => state.search);
  const { ItineraryRequest, direction, passengers } = reduxValues;

  const isOneway = direction === DirectionEnum.ONE_WAY;
  const isMulti = direction === DirectionEnum.MULTI;
  const history = useHistory();
  const passengersCount = countObjItems(passengers);
  const dateSingleFrom = convertShortDate(ItineraryRequest[0]?.departureDate);
  const dateRangeTo = !isOneway && convertShortDate(ItineraryRequest[1]?.departureDate);

  const goBackHandler = () => {
    return history.push(routes.home);
  };

  const headerContent = (
    <FlexRow justifyContent={'space-between'} width={'100%'} height={'100%'} padding={'10px 0'} alignItems={'end'}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{ItineraryRequest[0]?.originAirport?.airportCode}</Typography>
          <Box sx={{ mx: 1 }}>
            <ArrowRightIcon />
          </Box>
          <Typography>{ItineraryRequest[0]?.destinationAirport?.airportCode}</Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {isOneway || isMulti ? (
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={300}>
              {dateSingleFrom}
            </Typography>
          ) : (
            <>
              <Typography fontSize={16} lineHeight={'24px'} fontWeight={300}>
                {dateSingleFrom}
              </Typography>
              <Typography sx={{ mx: 1 }}> - </Typography>
              <Typography fontSize={16} lineHeight={'24px'} fontWeight={300}>
                {dateRangeTo}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <PersonIcon />
        {passengersCount}
      </Box>
      <IconButton onClick={goBackHandler} sx={{ color: colors.white }}>
        <EditIcon />
      </IconButton>
    </FlexRow>
  );
  return (
    <>
      <Header content={headerContent} />
      <ContainerStyled>
        <FlightOptions goBackHandler={goBackHandler} />
      </ContainerStyled>
    </>
  );
};
