import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { MobilePaymentForm } from '../MobilePaymentForm';
import { useAppSelector } from 'hooks/useAppSelector';
import { PaymentNetwork } from 'enum/enum';
import { stripePromise } from 'stripe/constants';
import { CheckoutForm } from 'components/pages/entry/forms/CheckoutForm';

interface PropTypes {
  setContactNumber: (contactNumber: string) => void;
  paymentLogo?: string;
  network?: PaymentNetwork;
  onSubmit: () => void;
}

export const EnterPaymentForm = ({ paymentLogo, network, onSubmit, setContactNumber }: PropTypes) => {
  const stripeSecretKey = useAppSelector((state) => state.payment.paymentStripe?.stripeClientSecret);
  const stripOptions = { clientSecret: stripeSecretKey };
  const isCreditCard = network === PaymentNetwork.CREDIT_CARD;

  return (
    <>
      {isCreditCard ? (
        <Elements stripe={stripePromise} options={stripOptions}>
          <CheckoutForm stripeClientSecret={stripeSecretKey as string} />
        </Elements>
      ) : (
        <MobilePaymentForm paymentLogo={paymentLogo} onSubmitHandler={onSubmit} setContactNumber={setContactNumber} />
      )}
    </>
  );
};
