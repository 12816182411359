import React from 'react';
import { Box, Button } from '@mui/material';
import { ButtonsBlock, CardSubtitle, CardTitle, CardWrapper } from './styles';
import { FlexColumn } from 'style';
import { MarginTop } from 'components';

type PropsType = {
  title: string;
  firstButtonTitle: string;
  subtitle?: string;
  children?: React.ReactChildren;
  isTwoButtons?: boolean;
  secondButtonTitle?: string;
  firstButtonFc?: () => void;
  secondButtonFc?: () => void;
};

export const Card = React.memo((props: PropsType) => {
  return (
    <CardWrapper>
      <FlexColumn justifyContent={'space-between'} height={'100%'}>
        <Box>
          <CardTitle>{props.title}</CardTitle>
          <MarginTop marginTop={'16'}>{props.children ? props.children : <CardSubtitle>{props.subtitle}</CardSubtitle>}</MarginTop>
        </Box>
        <ButtonsBlock>
          <Button onClick={props.firstButtonFc}>{props.firstButtonTitle}</Button>
          <Button onClick={props.secondButtonFc}>{props.secondButtonTitle}</Button>
        </ButtonsBlock>
      </FlexColumn>
    </CardWrapper>
  );
});
