import React, { ReactElement, useCallback } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import {
  DurationLine,
  ItemColumn,
  ItemWrapper,
  SegmentSquare,
  SegmentSquareArea,
  SliderWrapper,
  SquareDurationTime,
  SquareSegmentsWrapper,
  SquareSegmentWrapper,
  TypographyTime,
} from 'components/Itineraries/Itineraries/styles';
import { CardContentStyled, JustifyCenter } from 'style';
import { convertHoursMinutes, getTimeDifference } from 'utils/dates';
import { useWindowSize } from 'hooks/useWindowWidth';
import { DetailFlightOption } from 'components/Itineraries/DetailFlightOption/DetailFlightOption';
import { SegmentModel } from 'models/segment.model';
import { ImageWithLoader } from 'components/ImageWithLoader';

type PropsType = {
  segments: SegmentModel[];
  isShowMoreDetails: boolean;
  passengersCount: number;
  wayNumber: number;
};
const baseLogoUrl = process.env.REACT_APP_LOGO_BASE_URL;

export const Itineraries = ({ segments, isShowMoreDetails, passengersCount, wayNumber }: PropsType): ReactElement => {
  const width = useWindowSize().width;
  const departureWay = segments[0];
  const cabin = segments[0].cabin;
  const wayBack = segments[segments.length - 1];
  const logoDeparture = `${baseLogoUrl}${departureWay.validatingAirline.code}.png`;
  const logoArrival = `${baseLogoUrl}${wayBack.validatingAirline.code}.png`;
  const arrivalWay = segments[segments.length - 1];

  const setSegmentsSquares = useCallback((segmentArr: SegmentModel[]) => {
    if (segmentArr.length === 1) {
      const durationTime = getTimeDifference(segmentArr[0].departureAt, segmentArr[0].arrivalAt);
      return (
        <SquareSegmentWrapper>
          <SquareDurationTime>{durationTime}</SquareDurationTime>
        </SquareSegmentWrapper>
      );
    }
    if (segmentArr.length === 2) {
      const durationTime = getTimeDifference(segmentArr[0].departureAt, segmentArr[0].arrivalAt);

      return (
        <SquareSegmentWrapper>
          <SegmentSquareArea>
            <SegmentSquare />
            <SquareDurationTime>{durationTime}</SquareDurationTime>
          </SegmentSquareArea>
        </SquareSegmentWrapper>
      );
    }
    if (segmentArr.length > 2) {
      return (
        <SquareSegmentsWrapper>
          {segmentArr.slice(0, -1).map((segment) => {
            const durationTime = getTimeDifference(segment.departureAt, segment.arrivalAt);
            return (
              <SegmentSquareArea key={segment.id}>
                <SegmentSquare />
                <SquareDurationTime>{durationTime}</SquareDurationTime>
              </SegmentSquareArea>
            );
          })}
        </SquareSegmentsWrapper>
      );
    }
  }, []);

  return (
    <>
      {!isShowMoreDetails ? (
        <>
          <CardContentStyled>
            <ItemWrapper>
              <JustifyCenter>
                <ImageWithLoader width={35} height={40} src={logoDeparture} />
              </JustifyCenter>
              <ItemColumn>
                <TypographyTime>{convertHoursMinutes(departureWay.departureAt)}</TypographyTime>
                <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                  {departureWay.originAirport.code}
                </Typography>
              </ItemColumn>
              <SliderWrapper>
                <DurationLine>{setSegmentsSquares(segments)}</DurationLine>
              </SliderWrapper>
              <ItemColumn>
                <TypographyTime>{convertHoursMinutes(arrivalWay.arrivalAt)}</TypographyTime>
                <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
                  {arrivalWay.destinationAirport.code}
                </Typography>
              </ItemColumn>
            </ItemWrapper>
          </CardContentStyled>
        </>
      ) : (
        <Box>
          {wayNumber === 2 && <Divider sx={{ mb: 3 }} />}
          <DetailFlightOption
            directionName={wayNumber === 1 ? 'Departing' : 'Returning'}
            way={wayNumber === 1 ? departureWay : arrivalWay}
            segments={segments}
            width={width}
            logo={wayNumber === 1 ? logoDeparture : logoArrival}
            passengersCount={passengersCount}
            ticketClass={cabin}
          />
        </Box>
      )}
    </>
  );
};
