import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FLIGHT_OPTIONS, FLIGHT_OPTIONS_data, FLIGHT_OPTIONS_vars } from 'queries/options';
import { TicketClassEnum } from 'enum/enum';
import { useAppSelector } from 'hooks/useAppSelector';
import { ItineraryRequestApiModel, ItineraryRequestModel } from 'models/itinerary.model';
import { convertFullDate } from 'utils/dates';
import { setPaymentPassengerInfo } from 'redux/slices/payment/payment-slice';
import { setBookingPassengersValues } from 'redux/slices/booking/booking-slice';
import { useDispatch } from 'react-redux';

export const useGetRequestOptions = () => {
  /** Hooks **/
  const [foundedOptions, setFoundedOptions] = useState<FLIGHT_OPTIONS_data | null>(null);
  const dispatch = useDispatch();
  const passengerSearchInfo = useAppSelector((state) => state.search);
  const { ItineraryRequest, cabin, passengers, includeAmadeus, includeOrchestra, includeMultiticket, ndcProvider } = passengerSearchInfo;

  /** Requests **/
  const [getFlightOptionsRequest, { loading, data: optionsRequestData }] = useLazyQuery<FLIGHT_OPTIONS_data, FLIGHT_OPTIONS_vars>(FLIGHT_OPTIONS, {
    fetchPolicy: 'no-cache',
  });

  /** Handlers **/

  const convertItineraryRequest = (itinerary: ItineraryRequestModel): ItineraryRequestApiModel => {
    return {
      includeDaysAfter: itinerary.includeDaysAfter,
      includeDaysBefore: itinerary.includeDaysBefore,
      originAirportCode: itinerary.originAirport?.airportCode,
      destinationAirportCode: itinerary.destinationAirport?.airportCode,
      departureDate: convertFullDate(itinerary.departureDate),
    };
  };

  const finalItineraryRequest = (itineraries: ItineraryRequestModel[]) => {
    const finalIRequest: ItineraryRequestApiModel[] = [];
    itineraries.forEach((request, index) => {
      finalIRequest.push(convertItineraryRequest(itineraries[index]));
    });
    return finalIRequest;
  };

  const getRequestOptions = async () => {
    await getFlightOptionsRequest({
      variables: {
        requestedItineraries: finalItineraryRequest(ItineraryRequest) as ItineraryRequestApiModel[],
        cabin: TicketClassEnum[cabin],
        adults: passengers?.adults,
        children: passengers?.children,
        infants: passengers?.infants,
        includeAmadeus: includeAmadeus,
        includeOrchestra: includeOrchestra,
        includeMultiticket: includeMultiticket,
        ndcProvider: ndcProvider,
      },
      onCompleted: (data) => {
        // Transform the response data and save it in state.
        // In some cases we can get array of founded items and last elem is error because the back end
        // grab the data from few services, and we handle to avoid errors in our data result
        const transformedData = data?.journeyOffers?.filter((offer) => {
          return offer.__typename === 'JourneyOffer';
        });
        const result = {
          journeyOffers: transformedData || [],
          outbound: optionsRequestData?.outbound,
        };
        setFoundedOptions(result);
      },
    });
  };

  /** UseEffects **/
  useEffect(() => {
    getRequestOptions();
    dispatch(setBookingPassengersValues([]));
    dispatch(setPaymentPassengerInfo(null));
  }, []);

  return {
    data: foundedOptions,
    isLoading: loading,
  };
};
