import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { InputPhoneNumber } from './styles';
import { MuiPhoneNumberProps } from 'material-ui-phone-number';

interface IProps {
  name: string;
  control: Control<any>;
  error?: FieldError;
}

export const InputPhone = ({ control, name, error, ...props }: IProps & Omit<MuiPhoneNumberProps, 'variant' | 'onChange' | 'error'>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputPhoneNumber
          {...props}
          {...field}
          error={!!error}
          helperText={error?.message}
          value={field.value}
          onChange={field.onChange}
          disableAreaCodes={true}
          enableLongNumbers={true}
          defaultCountry={'so'}
          fullWidth
          variant={'outlined'}
        />
      )}
    />
  );
};
