// This file was generated by writeairportsts.py in the back end
// Do not add or modify airports listed in this file - the back end is the system of record
// Generated at: 2023-04-26T21:28:54.968284 UTC
export const airports = [
  { name: "Nnamdi Azikiwe International Airport", city: "Abuja", cityCode: "ABV", airportCode: "ABV" },
  { name: "General Juan N Alvarez International Airport", city: "Acapulco", cityCode: "ACA", airportCode: "ACA" },
  { name: "Kotoka International Airport", city: "Accra", cityCode: "ACC", airportCode: "ACC" },
  { name: "César Manrique-Lanzarote Airport", city: "Lanzarote", cityCode: "ACE", airportCode: "ACE" },
  { name: "Adnan Menderes International Airport", city: "Izmir", cityCode: "IZM", airportCode: "ADB" },
  { name: "Addis Ababa Bole International Airport", city: "Addis Ababa", cityCode: "ADD", airportCode: "ADD" },
  { name: "Adelaide International Airport", city: "Adelaide", cityCode: "ADL", airportCode: "ADL" },
  { name: "Jorge Newbery Airpark", city: "Buenos Aires", cityCode: "BUE", airportCode: "AEP" },
  { name: "Sochi International Airport", city: "Sochi", cityCode: "AER", airportCode: "AER" },
  { name: "Málaga-Costa del Sol Airport", city: "Malaga", cityCode: "AGP", airportCode: "AGP" },
  { name: "Auckland International Airport", city: "Auckland", cityCode: "AKL", airportCode: "AKL" },
  { name: "Almaty International Airport", city: "Almaty", cityCode: "ALA", airportCode: "ALA" },
  { name: "Alicante-Elche Miguel Hernández Airport", city: "Alicante", cityCode: "ALC", airportCode: "ALC" },
  { name: "Houari Boumediene Airport", city: "Algiers", cityCode: "ALG", airportCode: "ALG" },
  { name: "Queen Alia International Airport", city: "Amman", cityCode: "AMM", airportCode: "AMM" },
  { name: "Amsterdam Airport Schiphol", city: "Amsterdam", cityCode: "AMS", airportCode: "AMS" },
  { name: "Ted Stevens Anchorage International Airport", city: "Anchorage", cityCode: "ANC", airportCode: "ANC" },
  { name: "Stockholm-Arlanda Airport", city: "Stockholm", cityCode: "STO", airportCode: "ARN" },
  { name: "Ashgabat International Airport", city: "Ashgabat", cityCode: "ASB", airportCode: "ASB" },
  { name: "Athens Eleftherios Venizelos International Airport", city: "Athens", cityCode: "ATH", airportCode: "ATH" },
  { name: "Hartsfield Jackson Atlanta International Airport", city: "Atlanta", cityCode: "ATL", airportCode: "ATL" },
  { name: "Queen Beatrix International Airport", city: "Aruba", cityCode: "AUA", airportCode: "AUA" },
  { name: "Abu Dhabi International Airport", city: "Abu Dhabi", cityCode: "AUH", airportCode: "AUH" },
  { name: "Austin Bergstrom International Airport", city: "Austin", cityCode: "AUS", airportCode: "AUS" },
  { name: "Antalya International Airport", city: "Antalya", cityCode: "AYT", airportCode: "AYT" },
  { name: "Bahrain International Airport", city: "Bahrain", cityCode: "BAH", airportCode: "BAH" },
  { name: "Josep Tarradellas Barcelona-El Prat Airport", city: "Barcelona", cityCode: "BCN", airportCode: "BCN" },
  { name: "Brindisi Airport", city: "Brindisi", cityCode: "BDS", airportCode: "BDS" },
  { name: "Belgrade Nikola Tesla Airport", city: "Belgrade", cityCode: "BEG", airportCode: "BEG" },
  { name: "Val de Cans/Júlio Cezar Ribeiro International Airport", city: "Belem", cityCode: "BEL", airportCode: "BEL" },
  { name: "Berlin Brandenburg Airport", city: "Berlin", cityCode: "BER", airportCode: "BER" },
  { name: "Beirut Rafic Hariri International Airport", city: "Beirut", cityCode: "BEY", airportCode: "BEY" },
  { name: "Belfast International Airport", city: "Belfast", cityCode: "BFS", airportCode: "BFS" },
  { name: "Bergen Airport, Flesland", city: "Bergen", cityCode: "BGO", airportCode: "BGO" },
  { name: "Baghdad International Airport / New Al Muthana Air Base", city: "Baghdad", cityCode: "BGW", airportCode: "BGW" },
  { name: "Milan Bergamo Airport", city: "Milan", cityCode: "MIL", airportCode: "BGY" },
  { name: "Birmingham International Airport", city: "Birmingham", cityCode: "BHX", airportCode: "BHX" },
  { name: "Banjul International Airport", city: "Banjul", cityCode: "BJL", airportCode: "BJL" },
  { name: "Milas Bodrum International Airport", city: "Bodrum", cityCode: "BJV", airportCode: "BJV" },
  { name: "Suvarnabhumi Airport", city: "Bangkok", cityCode: "BKK", airportCode: "BKK" },
  { name: "Modibo Keita International Airport", city: "Bamako", cityCode: "BKO", airportCode: "BKO" },
  { name: "General José Antonio Anzoategui International Airport", city: "Barcelona", cityCode: "BLA", airportCode: "BLA" },
  { name: "Billund Airport", city: "Billund", cityCode: "BLL", airportCode: "BLL" },
  { name: "Bologna Guglielmo Marconi Airport", city: "Bologna", cityCode: "BLQ", airportCode: "BLQ" },
  { name: "Kempegowda International Airport", city: "Bengaluru", cityCode: "BLR", airportCode: "BLR" },
  { name: "Nashville International Airport", city: "Nashville", cityCode: "BNA", airportCode: "BNA" },
  { name: "Brisbane International Airport", city: "Brisbane", cityCode: "BNE", airportCode: "BNE" },
  { name: "Bordeaux-Mérignac Airport", city: "Bordeaux", cityCode: "BOD", airportCode: "BOD" },
  { name: "El Dorado International Airport", city: "Bogota", cityCode: "BOG", airportCode: "BOG" },
  { name: "Burgas Airport", city: "Burgas", cityCode: "BOJ", airportCode: "BOJ" },
  { name: "Chhatrapati Shivaji International Airport", city: "Mumbai", cityCode: "BOM", airportCode: "BOM" },
  { name: "Flamingo International Airport", city: "Bonaire", cityCode: "BON", airportCode: "BON" },
  { name: "Logan International Airport", city: "Boston", cityCode: "BOS", airportCode: "BOS" },
  { name: "Bari Karol Wojtyła Airport", city: "Bari", cityCode: "BRI", airportCode: "BRI" },
  { name: "Brussels Airport", city: "Brussels", cityCode: "BRU", airportCode: "BRU" },
  { name: "Presidente Juscelino Kubitschek International Airport", city: "Brasilia", cityCode: "BSB", airportCode: "BSB" },
  { name: "EuroAirport Basel-Mulhouse-Freiburg Airport", city: "Basel Mulhouse", cityCode: "EAP", airportCode: "BSL" },
  { name: "Bardera Airport", city: "Bardera", cityCode: "BSY", airportCode: "BSY" },
  { name: "M. R. Štefánik Airport", city: "Bratislava", cityCode: "BTS", airportCode: "BTS" },
  { name: "Budapest Liszt Ferenc International Airport", city: "Budapest", cityCode: "BUD", airportCode: "BUD" },
  { name: "Buffalo Niagara International Airport", city: "Buffalo", cityCode: "BUF", airportCode: "BUF" },
  { name: "Baltimore/Washington International Thurgood Marshall Airport", city: "Washington D.C.", cityCode: "WAS", airportCode: "BWI" },
  { name: "Brunei International Airport", city: "Bandar Seri Begawan", cityCode: "BWN", airportCode: "BWN" },
  { name: "Philip S. W. Goldson International Airport", city: "Belize City", cityCode: "BZE", airportCode: "BZE" },
  { name: "Cagliari Elmas Airport", city: "Cagliari", cityCode: "CAG", airportCode: "CAG" },
  { name: "Cairo International Airport", city: "Cairo", cityCode: "CAI", airportCode: "CAI" },
  { name: "Guangzhou Baiyun International Airport", city: "Guangzhou", cityCode: "CAN", airportCode: "CAN" },
  { name: "Cayenne – Félix Eboué Airport", city: "Cayenne", cityCode: "CAY", airportCode: "CAY" },
  { name: "Simón Bolívar International Airport", city: "Caracas", cityCode: "CCS", airportCode: "CCS" },
  { name: "Netaji Subhash Chandra Bose International Airport", city: "Kolkata", cityCode: "CCU", airportCode: "CCU" },
  { name: "Adado Airport", city: "Cadaado", cityCode: "AAD", airportCode: "AAD" },
  { name: "Baidoa Airport", city: "Baidoa", cityCode: "BIB", airportCode: "BIB" },
  { name: "Bosaso Airport", city: "Bosaso", cityCode: "BSA", airportCode: "BSA" },
  { name: "Charles de Gaulle International Airport", city: "Paris", cityCode: "PAR", airportCode: "CDG" },
  { name: "Mactan Cebu International Airport", city: "Cebu", cityCode: "CEB", airportCode: "CEB" },
  { name: "Soekarno-Hatta International Airport", city: "Jakarta", cityCode: "JKT", airportCode: "CGK" },
  { name: "Cologne Bonn Airport", city: "Cologne/Bonn", cityCode: "CGN", airportCode: "CGN" },
  { name: "Zhengzhou Xinzheng International Airport", city: "Zhengzhou", cityCode: "CGO", airportCode: "CGO" },
  { name: "Christchurch International Airport", city: "Christchurch", cityCode: "CHC", airportCode: "CHC" },
  { name: "Jeju International Airport", city: "Jeju", cityCode: "CJU", airportCode: "CJU" },
  { name: "Chongqing Jiangbei International Airport", city: "Chongqing", cityCode: "CKG", airportCode: "CKG" },
  { name: "Cleveland Hopkins International Airport", city: "Cleveland", cityCode: "CLE", airportCode: "CLE" },
  { name: "Charlotte Douglas International Airport", city: "Charlotte", cityCode: "CLT", airportCode: "CLT" },
  { name: "Bandaranaike International Colombo Airport", city: "Colombo", cityCode: "CMB", airportCode: "CMB" },
  { name: "John Glenn Columbus International Airport", city: "Columbus", cityCode: "CMH", airportCode: "CMH" },
  { name: "Mohammed V International Airport", city: "Casablanca", cityCode: "CMN", airportCode: "CMN" },
  { name: "Tancredo Neves International Airport", city: "Belo Horizonte", cityCode: "BHZ", airportCode: "CNF" },
  { name: "Chiang Mai International Airport", city: "Chiang Mai", cityCode: "CNX", airportCode: "CNX" },
  { name: "Cochin International Airport", city: "Kochi", cityCode: "COK", airportCode: "COK" },
  { name: "Copenhagen Kastrup Airport", city: "Copenhagen", cityCode: "CPH", airportCode: "CPH" },
  { name: "Cape Town International Airport", city: "Cape Town", cityCode: "CPT", airportCode: "CPT" },
  { name: "Changsha Huanghua International Airport", city: "Changsha", cityCode: "CSX", airportCode: "CSX" },
  { name: "Catania-Fontanarossa Airport", city: "Catania", cityCode: "CTA", airportCode: "CTA" },
  { name: "New Chitose Airport", city: "Sapporo", cityCode: "SPK", airportCode: "CTS" },
  { name: "Chengdu Shuangliu International Airport", city: "Chengdu", cityCode: "CTU", airportCode: "CTU" },
  { name: "Cancún International Airport", city: "Cancun", cityCode: "CUN", airportCode: "CUN" },
  { name: "Hato International Airport", city: "Curacao", cityCode: "CUR", airportCode: "CUR" },
  { name: "Alejandro Velasco Astete International Airport", city: "Cusco", cityCode: "CUZ", airportCode: "CUZ" },
  { name: "Cincinnati Northern Kentucky International Airport", city: "Cincinnati", cityCode: "CVG", airportCode: "CVG" },
  { name: "Hazrat Shahjalal International Airport", city: "Dhaka", cityCode: "DAC", airportCode: "DAC" },
  { name: "Damascus International Airport", city: "Damascus", cityCode: "DAM", airportCode: "DAM" },
  { name: "Ronald Reagan Washington National Airport", city: "Washington D.C.", cityCode: "WAS", airportCode: "DCA" },
  { name: "Indira Gandhi International Airport", city: "Delhi", cityCode: "DEL", airportCode: "DEL" },
  { name: "Denver International Airport", city: "Denver", cityCode: "DEN", airportCode: "DEN" },
  { name: "Dallas Fort Worth International Airport", city: "Dallas", cityCode: "DFW", airportCode: "DFW" },
  { name: "Sentani International Airport", city: "Jayapura", cityCode: "DJJ", airportCode: "DJJ" },
  { name: "Dalian Zhoushuizi International Airport", city: "Dalian", cityCode: "DLC", airportCode: "DLC" },
  { name: "Dalaman International Airport", city: "Dalaman", cityCode: "DLM", airportCode: "DLM" },
  { name: "Domodedovo International Airport", city: "Moscow", cityCode: "MOW", airportCode: "DME" },
  { name: "Don Mueang International Airport", city: "Bangkok", cityCode: "BKK", airportCode: "DMK" },
  { name: "King Fahd International Airport", city: "Dammam", cityCode: "DMM", airportCode: "DMM" },
  { name: "Hamad International Airport", city: "Doha", cityCode: "DOH", airportCode: "DOH" },
  { name: "Ngurah Rai (Bali) International Airport", city: "Denpasar-Bali", cityCode: "DPS", airportCode: "DPS" },
  { name: "Duqm International Airport", city: "Duqm", cityCode: "DQM", airportCode: "DQM" },
  { name: "Blaise Diagne International Airport", city: "Dakar", cityCode: "DKR", airportCode: "DSS" },
  { name: "Detroit Metropolitan Wayne County Airport", city: "Detroit", cityCode: "DTT", airportCode: "DTW" },
  { name: "Dublin Airport", city: "Dublin", cityCode: "DUB", airportCode: "DUB" },
  { name: "King Shaka International Airport", city: "Durban", cityCode: "DUR", airportCode: "DUR" },
  { name: "Düsseldorf Airport", city: "Duesseldorf", cityCode: "DUS", airportCode: "DUS" },
  { name: "Francisco Bangoy International Airport", city: "Davao", cityCode: "DVO", airportCode: "DVO" },
  { name: "Al Maktoum International Airport", city: "Dubai", cityCode: "DXB", airportCode: "DWC" },
  { name: "Dubai International Airport", city: "Dubai", cityCode: "DXB", airportCode: "DXB" },
  { name: "Entebbe International Airport", city: "Entebbe", cityCode: "EBB", airportCode: "EBB" },
  { name: "Edinburgh Airport", city: "Edinburgh", cityCode: "EDI", airportCode: "EDI" },
  { name: "Eindhoven Airport", city: "Eindhoven", cityCode: "EIN", airportCode: "EIN" },
  { name: "Erigavo Airport", city: "Ceerigaabo", cityCode: "ERA", airportCode: "ERA" },
  { name: "Esenboğa International Airport", city: "Ankara", cityCode: "ANK", airportCode: "ESB" },
  { name: "Ramon International Airport", city: "Eilat", cityCode: "ETH", airportCode: "ETM" },
  { name: "Zvartnots International Airport", city: "Yerevan", cityCode: "EVN", airportCode: "EVN" },
  { name: "Newark Liberty International Airport", city: "New York", cityCode: "NYC", airportCode: "EWR" },
  { name: "Ministro Pistarini International Airport", city: "Buenos Aires", cityCode: "BUE", airportCode: "EZE" },
  { name: "Fairbanks International Airport", city: "Fairbanks", cityCode: "FAI", airportCode: "FAI" },
  { name: "Faro Airport", city: "Faro", cityCode: "FAO", airportCode: "FAO" },
  { name: "Rome–Fiumicino Leonardo da Vinci International Airport", city: "Rome", cityCode: "ROM", airportCode: "FCO" },
  { name: "Martinique Aimé Césaire International Airport", city: "Fort De France", cityCode: "FDF", airportCode: "FDF" },
  { name: "Ndjili International Airport", city: "Kinshasa", cityCode: "FIH", airportCode: "FIH" },
  { name: "Fort Lauderdale Hollywood International Airport", city: "Ft Lauderdale", cityCode: "FLL", airportCode: "FLL" },
  { name: "Hercílio Luz International Airport", city: "Florianopolis", cityCode: "FLN", airportCode: "FLN" },
  { name: "Lungi International Airport", city: "Freetown", cityCode: "FNA", airportCode: "FNA" },
  { name: "Frankfurt am Main Airport", city: "Frankfurt", cityCode: "FRA", airportCode: "FRA" },
  { name: "Manas International Airport", city: "Bishkek", cityCode: "FRU", airportCode: "FRU" },
  { name: "Fuerteventura Airport", city: "Fuerteventura", cityCode: "FUE", airportCode: "FUE" },
  { name: "Fukuoka Airport", city: "Fukuoka", cityCode: "FUK", airportCode: "FUK" },
  { name: "Sir Seretse Khama International Airport", city: "Gaborone", cityCode: "GBE", airportCode: "GBE" },
  { name: "Owen Roberts International Airport", city: "Grand Cayman", cityCode: "GCM", airportCode: "GCM" },
  { name: "Don Miguel Hidalgo Y Costilla International Airport", city: "Guadalajara", cityCode: "GDL", airportCode: "GDL" },
  { name: "Gdańsk Lech Wałęsa Airport", city: "Gdansk", cityCode: "GDN", airportCode: "GDN" },
  { name: "Nouméa Magenta Airport", city: "Noumea", cityCode: "NOU", airportCode: "GEA" },
  { name: "Rio Galeão – Tom Jobim International Airport", city: "Rio De Janeiro", cityCode: "RIO", airportCode: "GIG" },
  { name: "Glasgow International Airport", city: "Glasgow", cityCode: "GLA", airportCode: "GLA" },
  { name: "Gimpo International Airport", city: "Seoul", cityCode: "SEL", airportCode: "GMP" },
  { name: "Dabolim Airport", city: "Goa", cityCode: "GOI", airportCode: "GOI" },
  { name: "Gothenburg-Landvetter Airport", city: "Goteborg", cityCode: "GOT", airportCode: "GOT" },
  { name: "Guarulhos - Governador André Franco Montoro International Airport", city: "Sao Paulo", cityCode: "SAO", airportCode: "GRU" },
  { name: "Gagarin International Airport", city: "Saratov", cityCode: "RTW", airportCode: "GSV" },
  { name: "La Aurora Airport", city: "Guatemala City", cityCode: "GUA", airportCode: "GUA" },
  { name: "Antonio B. Won Pat International Airport", city: "Guam", cityCode: "GUM", airportCode: "GUM" },
  { name: "Geneva Cointrin International Airport", city: "Geneva", cityCode: "GVA", airportCode: "GVA" },
  { name: "Heydar Aliyev International Airport", city: "Baku", cityCode: "BAK", airportCode: "GYD" },
  { name: "José Joaquín de Olmedo International Airport", city: "Guayaquil", cityCode: "GYE", airportCode: "GYE" },
  { name: "Hannover Airport", city: "Hannover", cityCode: "HAJ", airportCode: "HAJ" },
  { name: "Haikou Meilan International Airport", city: "Haikou", cityCode: "HAK", airportCode: "HAK" },
  { name: "Hamburg Helmut Schmidt Airport", city: "Hamburg", cityCode: "HAM", airportCode: "HAM" },
  { name: "Noi Bai International Airport", city: "Hanoi", cityCode: "HAN", airportCode: "HAN" },
  { name: "José Martí International Airport", city: "Havana", cityCode: "HAV", airportCode: "HAV" },
  { name: "Helsinki Vantaa Airport", city: "Helsinki", cityCode: "HEL", airportCode: "HEL" },
  { name: "Heraklion International Nikos Kazantzakis Airport", city: "Irakleion", cityCode: "HER", airportCode: "HER" },
  { name: "Baita International Airport", city: "Hohhot", cityCode: "HET", airportCode: "HET" },
  { name: "Hangzhou Xiaoshan International Airport", city: "Hangzhou", cityCode: "HGH", airportCode: "HGH" },
  { name: "Honiara International Airport", city: "Honiara", cityCode: "HIR", airportCode: "HIR" },
  { name: "Hong Kong International Airport", city: "Hong Kong", cityCode: "HKG", airportCode: "HKG" },
  { name: "Phuket International Airport", city: "Phuket", cityCode: "HKT", airportCode: "HKT" },
  { name: "General Ignacio P. Garcia International Airport", city: "Hermosillo", cityCode: "HMO", airportCode: "HMO" },
  { name: "Tokyo Haneda International Airport", city: "Tokyo", cityCode: "TYO", airportCode: "HND" },
  { name: "Daniel K Inouye International Airport", city: "Honolulu", cityCode: "HNL", airportCode: "HNL" },
  { name: "Harbin Taiping International Airport", city: "Harbin", cityCode: "HRB", airportCode: "HRB" },
  { name: "Robert Gabriel Mugabe International Airport", city: "Harare", cityCode: "HRE", airportCode: "HRE" },
  { name: "Hurghada International Airport", city: "Hurghada", cityCode: "HRG", airportCode: "HRG" },
  { name: "Mattala Rajapaksa International Airport", city: "Hambantota", cityCode: "HRI", airportCode: "HRI" },
  { name: "Hazrat Sultan International Airport", city: "Turkistan", cityCode: "HSA", airportCode: "HSA" },
  { name: "Rajiv Gandhi International Airport", city: "Hyderabad", cityCode: "HYD", airportCode: "HYD" },
  { name: "Washington Dulles International Airport", city: "Washington D.C.", cityCode: "WAS", airportCode: "IAD" },
  { name: "George Bush Intercontinental Houston Airport", city: "Houston", cityCode: "HOU", airportCode: "IAH" },
  { name: "Ibiza Airport", city: "Ibiza", cityCode: "IBZ", airportCode: "IBZ" },
  { name: "Incheon International Airport", city: "Seoul", cityCode: "SEL", airportCode: "ICN" },
  { name: "Imam Khomeini International Airport", city: "Tehran", cityCode: "THR", airportCode: "IKA" },
  { name: "Indianapolis International Airport", city: "Indianapolis", cityCode: "IND", airportCode: "IND" },
  { name: "Islamabad International Airport", city: "Islamabad", cityCode: "ISB", airportCode: "ISB" },
  { name: "İstanbul Atatürk Airport", city: "Istanbul", cityCode: "IST", airportCode: "ISL" },
  { name: "İstanbul Airport", city: "Istanbul", cityCode: "IST", airportCode: "IST" },
  { name: "Osaka International Airport", city: "Osaka", cityCode: "OSA", airportCode: "ITM" },
  { name: "Jacksonville International Airport", city: "Jacksonville", cityCode: "JAX", airportCode: "JAX" },
  { name: "King Abdulaziz International Airport", city: "Jeddah", cityCode: "JED", airportCode: "JED" },
  { name: "John F Kennedy International Airport", city: "New York", cityCode: "NYC", airportCode: "JFK" },
  { name: "Djibouti-Ambouli Airport", city: "Djibouti", cityCode: "JIB", airportCode: "JIB" },
  { name: "OR Tambo International Airport", city: "Johannesburg", cityCode: "JNB", airportCode: "JNB" },
  { name: "Juba International Airport", city: "Juba", cityCode: "JUB", airportCode: "JUB" },
  { name: "Boryspil International Airport", city: "Kyiv", cityCode: "IEV", airportCode: "KBP" },
  { name: "Keflavik International Airport", city: "Reykjavik", cityCode: "REK", airportCode: "KEF" },
  { name: "Kigali International Airport", city: "Kigali", cityCode: "KGL", airportCode: "KGL" },
  { name: "Kaohsiung International Airport", city: "Kaohsiung", cityCode: "KHH", airportCode: "KHH" },
  { name: "Jinnah International Airport", city: "Karachi", cityCode: "KHI", airportCode: "KHI" },
  { name: "Norman Manley International Airport", city: "Guayaquil", cityCode: "GYE", airportCode: "KIN" },
  { name: "Kansai International Airport", city: "Osaka", cityCode: "OSA", airportCode: "KIX" },
  { name: "Krasnoyarsk International Airport", city: "Krasnoyarsk", cityCode: "KJA", airportCode: "KJA" },
  { name: "Kunming Changshui International Airport", city: "Kunming", cityCode: "KMG", airportCode: "KMG" },
  { name: "Kagoshima Airport", city: "Kagoshima", cityCode: "KOJ", airportCode: "KOJ" },
  { name: "Kraków John Paul II International Airport", city: "Krakow", cityCode: "KRK", airportCode: "KRK" },
  { name: "Khartoum International Airport", city: "Khartoum", cityCode: "KRT", airportCode: "KRT" },
  { name: "Tribhuvan International Airport", city: "Kathmandu", cityCode: "KTM", airportCode: "KTM" },
  { name: "Kurumoch International Airport", city: "Samara", cityCode: "KUF", airportCode: "KUF" },
  { name: "Kuala Lumpur International Airport", city: "Kuala Lumpur", cityCode: "KUL", airportCode: "KUL" },
  { name: "Longdongbao Airport", city: "Guiyang", cityCode: "KWE", airportCode: "KWE" },
  { name: "Kuwait International Airport", city: "Kuwait", cityCode: "KWI", airportCode: "KWI" },
  { name: "Guilin Liangjiang International Airport", city: "Guilin", cityCode: "KWL", airportCode: "KWL" },
  { name: "Kazan International Airport", city: "Kazan", cityCode: "KZN", airportCode: "KZN" },
  { name: "Sofia Airport", city: "Sofia", cityCode: "SOF", airportCode: "SOF" },
  { name: "Garowe Airport", city: "Garowe", cityCode: "GGR", airportCode: "GGR" },
  { name: "Kismayo Airport", city: "Kismaayo", cityCode: "KMU", airportCode: "KMU" },
  { name: "Quatro de Fevereiro International Airport", city: "Luanda", cityCode: "LAD", airportCode: "LAD" },
  { name: "McCarran International Airport", city: "Las Vegas", cityCode: "LAS", airportCode: "LAS" },
  { name: "Los Angeles International Airport", city: "Los Angeles", cityCode: "LAX", airportCode: "LAX" },
  { name: "Larnaca International Airport", city: "Larnaca", cityCode: "LCA", airportCode: "LCA" },
  { name: "Pulkovo Airport", city: "St Petersburg", cityCode: "LED", airportCode: "LED" },
  { name: "Leipzig/Halle Airport", city: "Leipzig/Halle", cityCode: "LEJ", airportCode: "LEJ" },
  { name: "La Guardia Airport", city: "New York", cityCode: "NYC", airportCode: "LGA" },
  { name: "London Gatwick Airport", city: "London", cityCode: "LON", airportCode: "LGW" },
  { name: "Allama Iqbal International Airport", city: "Lahore", cityCode: "LHE", airportCode: "LHE" },
  { name: "London Heathrow Airport", city: "London", cityCode: "LON", airportCode: "LHR" },
  { name: "Lanzhou Zhongchuan International Airport", city: "Lanzhou", cityCode: "LHW", airportCode: "LHW" },
  { name: "Jorge Chávez International Airport", city: "Lima", cityCode: "LIM", airportCode: "LIM" },
  { name: "Guanacaste Airport", city: "Guanacaste", cityCode: "LIR", airportCode: "LIR" },
  { name: "Humberto Delgado Airport (Lisbon Portela Airport)", city: "Lisbon", cityCode: "LIS", airportCode: "LIS" },
  { name: "Ljubljana Jože Pučnik Airport", city: "Ljubljana", cityCode: "LJU", airportCode: "LJU" },
  { name: "Murtala Muhammed International Airport", city: "Lagos", cityCode: "LOS", airportCode: "LOS" },
  { name: "Gran Canaria Airport", city: "Gran Canaria", cityCode: "LPA", airportCode: "LPA" },
  { name: "London Luton Airport", city: "London", cityCode: "LON", airportCode: "LTN" },
  { name: "Kenneth Kaunda International Airport", city: "Lusaka", cityCode: "LUN", airportCode: "LUN" },
  { name: "Luxembourg-Findel International Airport", city: "Luxembourg", cityCode: "LUX", airportCode: "LUX" },
  { name: "Lviv International Airport", city: "Lviv", cityCode: "LWO", airportCode: "LWO" },
  { name: "Lyon Saint-Exupéry Airport", city: "Lyon", cityCode: "LYS", airportCode: "LYS" },
  { name: "Chennai International Airport", city: "Chennai", cityCode: "MAA", airportCode: "MAA" },
  { name: "Adolfo Suárez Madrid–Barajas Airport", city: "Madrid", cityCode: "MAD", airportCode: "MAD" },
  { name: "Manchester Airport", city: "Manchester", cityCode: "MAN", airportCode: "MAN" },
  { name: "Eduardo Gomes International Airport", city: "Manaus", cityCode: "MAO", airportCode: "MAO" },
  { name: "Momote Airport", city: "Manus Island", cityCode: "MAS", airportCode: "MAS" },
  { name: "Moi International Airport", city: "Mombasa", cityCode: "MBA", airportCode: "MBA" },
  { name: "Kansas City International Airport", city: "Kansas City", cityCode: "MKC", airportCode: "MCI" },
  { name: "Orlando International Airport", city: "Orlando", cityCode: "ORL", airportCode: "MCO" },
  { name: "Muscat International Airport", city: "Muscat", cityCode: "MCT", airportCode: "MCT" },
  { name: "Mandalay International Airport", city: "Mandalay", cityCode: "MDL", airportCode: "MDL" },
  { name: "Chicago Midway International Airport", city: "Chicago", cityCode: "CHI", airportCode: "MDW" },
  { name: "Prince Mohammad Bin Abdulaziz Airport", city: "Madinah", cityCode: "MED", airportCode: "MED" },
  { name: "Melbourne International Airport", city: "Melbourne", cityCode: "MEL", airportCode: "MEL" },
  { name: "Memphis International Airport", city: "Memphis", cityCode: "MEM", airportCode: "MEM" },
  { name: "Licenciado Benito Juarez International Airport", city: "Mexico City", cityCode: "MEX", airportCode: "MEX" },
  { name: "Macau International Airport", city: "Macau", cityCode: "MFM", airportCode: "MFM" },
  { name: "Mashhad International Airport", city: "Mashhad", cityCode: "MHD", airportCode: "MHD" },
  { name: "Miami International Airport", city: "Miami", cityCode: "MIA", airportCode: "MIA" },
  { name: "General Mitchell International Airport", city: "Milwaukee", cityCode: "MKE", airportCode: "MKE" },
  { name: "Malta International Airport", city: "Malta", cityCode: "MLA", airportCode: "MLA" },
  { name: "Malé International Airport", city: "Male", cityCode: "MLE", airportCode: "MLE" },
  { name: "Ninoy Aquino International Airport", city: "Manila", cityCode: "MNL", airportCode: "MNL" },
  { name: "Maputo Airport", city: "Maputo", cityCode: "MPM", airportCode: "MPM" },
  { name: "Marseille Provence Airport", city: "Marseille", cityCode: "MRS", airportCode: "MRS" },
  { name: "Sir Seewoosagur Ramgoolam International Airport", city: "Mauritius", cityCode: "MRU", airportCode: "MRU" },
  { name: "Minneapolis–Saint Paul International Airport / Wold–Chamberlain Field", city: "Minneapolis/St Paul", cityCode: "MSP", airportCode: "MSP" },
  { name: "Minsk National Airport", city: "Minsk", cityCode: "MSQ", airportCode: "MSQ" },
  { name: "Louis Armstrong New Orleans International Airport", city: "New Orleans", cityCode: "MSY", airportCode: "MSY" },
  { name: "Munich Airport", city: "Munich", cityCode: "MUC", airportCode: "MUC" },
  { name: "Carrasco International /General C L Berisso Airport", city: "Montevideo", cityCode: "MVD", airportCode: "MVD" },
  { name: "Muan International Airport", city: "Muan", cityCode: "MWX", airportCode: "MWX" },
  { name: "Malpensa International Airport", city: "Milan", cityCode: "MIL", airportCode: "MXP" },
  { name: "General Rafael Buelna International Airport", city: "Mazatlan", cityCode: "MZT", airportCode: "MZT" },
  { name: "Naples International Airport", city: "Naples", cityCode: "NAP", airportCode: "NAP" },
  { name: "Lynden Pindling International Airport", city: "Nassau", cityCode: "NAS", airportCode: "NAS" },
  { name: "Nice-Côte d'Azur Airport", city: "Nice", cityCode: "NCE", airportCode: "NCE" },
  { name: "N'Djamena International Airport", city: "N'Djamena", cityCode: "NDJ", airportCode: "NDJ" },
  { name: "Ningbo Lishe International Airport", city: "Ningbo", cityCode: "NGB", airportCode: "NGB" },
  { name: "Chubu Centrair International Airport", city: "Nagoya", cityCode: "NGO", airportCode: "NGO" },
  { name: "Diori Hamani International Airport", city: "Niamey", cityCode: "NIM", airportCode: "NIM" },
  { name: "Nouakchott–Oumtounsy International Airport", city: "Nouakchott", cityCode: "NKC", airportCode: "NKC" },
  { name: "Nanjing Lukou International Airport", city: "Nanjing", cityCode: "NKG", airportCode: "NKG" },
  { name: "Nanning Wuxu Airport", city: "Nanning", cityCode: "NNG", airportCode: "NNG" },
  { name: "Nursultan Nazarbayev International Airport", city: "Nur-Sultan", cityCode: "NQZ", airportCode: "NQZ" },
  { name: "Narita International Airport", city: "Tokyo", cityCode: "TYO", airportCode: "NRT" },
  { name: "Nuremberg Airport", city: "Nuremberg", cityCode: "NUE", airportCode: "NUE" },
  { name: "Metropolitan Oakland International Airport", city: "Oakland", cityCode: "OAK", airportCode: "OAK" },
  { name: "Naha Airport / JASDF Naha Air Base", city: "Okinawa", cityCode: "OKA", airportCode: "OKA" },
  { name: "Ontario International Airport", city: "Los Angeles", cityCode: "LAX", airportCode: "ONT" },
  { name: "Francisco de Sá Carneiro Airport", city: "Porto", cityCode: "OPO", airportCode: "OPO" },
  { name: "Aden Adde International Airport", city: "Mogadishu", cityCode: "MGQ", airportCode: "MGQ" },
  { name: "Chicago O'Hare International Airport", city: "Chicago", cityCode: "CHI", airportCode: "ORD" },
  { name: "Paris-Orly Airport", city: "Paris", cityCode: "PAR", airportCode: "ORY" },
  { name: "Oslo Airport, Gardermoen", city: "Oslo", cityCode: "OSL", airportCode: "OSL" },
  { name: "Henri Coandă International Airport", city: "Bucharest", cityCode: "BUH", airportCode: "OTP" },
  { name: "Novosibirsk Tolmachevo Airport", city: "Novosibirsk", cityCode: "OVB", airportCode: "OVB" },
  { name: "Toussaint Louverture International Airport", city: "Port Au Prince", cityCode: "PAP", airportCode: "PAP" },
  { name: "Palm Beach International Airport", city: "West Palm Beach", cityCode: "PBI", airportCode: "PBI" },
  { name: "Johan Adolf Pengel International Airport", city: "Paramaribo", cityCode: "PBM", airportCode: "PBM" },
  { name: "João Paulo II Airport", city: "Ponta Delgada", cityCode: "PDL", airportCode: "PDL" },
  { name: "Portland International Airport", city: "Portland", cityCode: "PDX", airportCode: "PDX" },
  { name: "Beijing Capital International Airport", city: "Beijing", cityCode: "BJS", airportCode: "PEK" },
  { name: "Perth International Airport", city: "Perth", cityCode: "PER", airportCode: "PER" },
  { name: "Philadelphia International Airport", city: "Philadelphia", cityCode: "PHL", airportCode: "PHL" },
  { name: "Phoenix Sky Harbor International Airport", city: "Phoenix", cityCode: "PHX", airportCode: "PHX" },
  { name: "Pittsburgh International Airport", city: "Pittsburgh", cityCode: "PIT", airportCode: "PIT" },
  { name: "Beijing Daxing International Airport", city: "Beijing", cityCode: "BJS", airportCode: "PKX" },
  { name: "Providenciales International Airport", city: "Providenciales", cityCode: "PLS", airportCode: "PLS" },
  { name: "Palma de Mallorca Airport", city: "Palma De Mallorca", cityCode: "PMI", airportCode: "PMI" },
  { name: "Falcone–Borsellino Airport", city: "Palermo", cityCode: "PMO", airportCode: "PMO" },
  { name: "Phnom Penh International Airport", city: "Phnom Penh", cityCode: "PNH", airportCode: "PNH" },
  { name: "Port Moresby Jacksons International Airport", city: "Port Moresby", cityCode: "POM", airportCode: "POM" },
  { name: "Faa'a International Airport", city: "Tahiti", cityCode: "PPT", airportCode: "PPT" },
  { name: "Václav Havel Airport Prague", city: "Prague", cityCode: "PRG", airportCode: "PRG" },
  { name: "Pisa International Airport", city: "Pisa", cityCode: "PSA", airportCode: "PSA" },
  { name: "Pointe-à-Pitre Le Raizet International  Airport", city: "Pointe-A-Pitre", cityCode: "PTP", airportCode: "PTP" },
  { name: "Tocumen International Airport", city: "Panama City", cityCode: "PTY", airportCode: "PTY" },
  { name: "Punta Cana International Airport", city: "Punta Cana", cityCode: "PUJ", airportCode: "PUJ" },
  { name: "Gimhae International Airport", city: "Busan", cityCode: "PUS", airportCode: "PUS" },
  { name: "Theodore Francis Green State Airport", city: "Providence", cityCode: "PVD", airportCode: "PVD" },
  { name: "Shanghai Pudong International Airport", city: "Shanghai", cityCode: "SHA", airportCode: "PVG" },
  { name: "President Gustavo Díaz Ordaz International Airport", city: "Puerto Vallarta", cityCode: "PVR", airportCode: "PVR" },
  { name: "Portland International Jetport", city: "Portland", cityCode: "PWM", airportCode: "PWM" },
  { name: "Rarotonga International Airport", city: "Rarotonga Island", cityCode: "RAR", airportCode: "RAR" },
  { name: "Raleigh Durham International Airport", city: "Raleigh Durham", cityCode: "RDU", airportCode: "RDU" },
  { name: "Siem Reap International Airport", city: "Siem Reap", cityCode: "REP", airportCode: "REP" },
  { name: "Yangon International Airport", city: "Yangon", cityCode: "RGN", airportCode: "RGN" },
  { name: "Richmond International Airport", city: "Richmond", cityCode: "RIC", airportCode: "RIC" },
  { name: "Riga International Airport", city: "Riga", cityCode: "RIX", airportCode: "RIX" },
  { name: "Reno Tahoe International Airport", city: "Reno", cityCode: "RNO", airportCode: "RNO" },
  { name: "Roberts International Airport", city: "Monrovia", cityCode: "MLW", airportCode: "ROB" },
  { name: "Platov International Airport", city: "Rostov", cityCode: "ROV", airportCode: "ROV" },
  { name: "Southwest Florida International Airport", city: "Fort Myers", cityCode: "FMY", airportCode: "RSW" },
  { name: "King Khaled International Airport", city: "Riyadh", cityCode: "RUH", airportCode: "RUH" },
  { name: "Roland Garros Airport", city: "St Denis", cityCode: "RUN", airportCode: "RUN" },
  { name: "Monseñor Óscar Arnulfo Romero International Airport", city: "San Salvador", cityCode: "SAL", airportCode: "SAL" },
  { name: "San Diego International Airport", city: "San Diego", cityCode: "SAN", airportCode: "SAN" },
  { name: "San Antonio International Airport", city: "San Antonio", cityCode: "SAT", airportCode: "SAT" },
  { name: "Savannah Hilton Head International Airport", city: "Savannah", cityCode: "SAV", airportCode: "SAV" },
  { name: "Istanbul Sabiha Gökçen International Airport", city: "Istanbul", cityCode: "IST", airportCode: "SAW" },
  { name: "Comodoro Arturo Merino Benítez International Airport", city: "Santiago", cityCode: "SCL", airportCode: "SCL" },
  { name: "Santiago-Rosalía de Castro Airport", city: "Santiago De Compostela", cityCode: "SCQ", airportCode: "SCQ" },
  { name: "Louisville Muhammad Ali International Airport", city: "Louisville", cityCode: "SDF", airportCode: "SDF" },
  { name: "Sendai Airport", city: "Sendai", cityCode: "SDJ", airportCode: "SDJ" },
  { name: "Las Américas International Airport", city: "Santo Domingo", cityCode: "SDQ", airportCode: "SDQ" },
  { name: "Seattle Tacoma International Airport", city: "Seattle", cityCode: "SEA", airportCode: "SEA" },
  { name: "Seychelles International Airport", city: "Mahe Island", cityCode: "SEZ", airportCode: "SEZ" },
  { name: "Orlando Sanford International Airport", city: "Orlando", cityCode: "ORL", airportCode: "SFB" },
  { name: "San Francisco International Airport", city: "San Francisco", cityCode: "SFO", airportCode: "SFO" },
  { name: "Tan Son Nhat International Airport", city: "Ho Chi Minh City", cityCode: "SGN", airportCode: "SGN" },
  { name: "Shanghai Hongqiao International Airport", city: "Shanghai", cityCode: "SHA", airportCode: "SHA" },
  { name: "Shenyang Taoxian International Airport", city: "Shenyang", cityCode: "SHE", airportCode: "SHE" },
  { name: "Sharjah International Airport", city: "Sharjah", cityCode: "SHJ", airportCode: "SHJ" },
  { name: "King Mswati III International Airport", city: "Manzini", cityCode: "MTS", airportCode: "SHO" },
  { name: "Amílcar Cabral International Airport", city: "Sal Island", cityCode: "SID", airportCode: "SID" },
  { name: "Singapore Changi Airport", city: "Singapore", cityCode: "SIN", airportCode: "SIN" },
  { name: "Norman Y. Mineta San Jose International Airport", city: "San Jose", cityCode: "SJC", airportCode: "SJC" },
  { name: "Los Cabos International Airport", city: "San Jose Del Cabo", cityCode: "SJD", airportCode: "SJD" },
  { name: "Luis Munoz Marin International Airport", city: "San Juan", cityCode: "SJU", airportCode: "SJU" },
  { name: "Thessaloniki Macedonia International Airport", city: "Thessaloniki", cityCode: "SKG", airportCode: "SKG" },
  { name: "Skopje International Airport", city: "Skopje", cityCode: "SKP", airportCode: "SKP" },
  { name: "Salt Lake City International Airport", city: "Salt Lake City", cityCode: "SLC", airportCode: "SLC" },
  { name: "Sacramento International Airport", city: "Sacramento", cityCode: "SAC", airportCode: "SMF" },
  { name: "Shannon Airport", city: "Shannon", cityCode: "SNN", airportCode: "SNN" },
  { name: "Deputado Luiz Eduardo Magalhães International Airport", city: "Salvador", cityCode: "SSA", airportCode: "SSA" },
  { name: "Sharm El Sheikh International Airport", city: "Sharm El Sheikh", cityCode: "SSH", airportCode: "SSH" },
  { name: "St Louis Lambert International Airport", city: "St Louis", cityCode: "STL", airportCode: "STL" },
  { name: "London Stansted Airport", city: "London", cityCode: "LON", airportCode: "STN" },
  { name: "Stuttgart Airport", city: "Stuttgart", cityCode: "STR", airportCode: "STR" },
  { name: "Juanda International Airport", city: "Surabaya", cityCode: "SUB", airportCode: "SUB" },
  { name: "Stavanger Airport, Sola", city: "Stavanger", cityCode: "SVG", airportCode: "SVG" },
  { name: "Sheremetyevo International Airport", city: "Moscow", cityCode: "MOW", airportCode: "SVO" },
  { name: "Koltsovo Airport", city: "Yekaterinburg", cityCode: "SVX", airportCode: "SVX" },
  { name: "Princess Juliana International Airport", city: "St Maarten", cityCode: "SXM", airportCode: "SXM" },
  { name: "Sydney Kingsford Smith International Airport", city: "Sydney", cityCode: "SYD", airportCode: "SYD" },
  { name: "Syracuse Hancock International Airport", city: "Syracuse", cityCode: "SYR", airportCode: "SYR" },
  { name: "Sanya Phoenix International Airport", city: "Sanya", cityCode: "SYX", airportCode: "SYX" },
  { name: "Shiraz Shahid Dastghaib International Airport", city: "Shiraz", cityCode: "SYZ", airportCode: "SYZ" },
  { name: "Shenzhen Bao'an International Airport", city: "Shenzhen", cityCode: "SZX", airportCode: "SZX" },
  { name: "Qingdao Jiaodong International Airport", city: "Qingdao", cityCode: "TAO", airportCode: "TAO" },
  { name: "Tashkent International Airport", city: "Tashkent", cityCode: "TAS", airportCode: "TAS" },
  { name: "Tbilisi International Airport", city: "Tbilisi", cityCode: "TBS", airportCode: "TBS" },
  { name: "Tenerife Sur Airport", city: "Tenerife", cityCode: "TCI", airportCode: "TFS" },
  { name: "Chengdu Tianfu International Airport", city: "Chengdu", cityCode: "CTU", airportCode: "TFU" },
  { name: "Podgorica Airport / Podgorica Golubovci Airbase", city: "Podgorica", cityCode: "TGD", airportCode: "TGD" },
  { name: "Mehrabad International Airport", city: "Tehran", cityCode: "THR", airportCode: "THR" },
  { name: "Tirana International Airport Mother Teresa", city: "Tirana", cityCode: "TIA", airportCode: "TIA" },
  { name: "General Abelardo L. Rodríguez International Airport", city: "Tijuana", cityCode: "TIJ", airportCode: "TIJ" },
  { name: "Tripoli International Airport", city: "Tripoli", cityCode: "TIP", airportCode: "TIP" },
  { name: "Lennart Meri Tallinn Airport", city: "Tallinn", cityCode: "TLL", airportCode: "TLL" },
  { name: "Toulouse-Blagnac Airport", city: "Toulouse", cityCode: "TLS", airportCode: "TLS" },
  { name: "Ben Gurion International Airport", city: "Tel Aviv Yafo", cityCode: "TLV", airportCode: "TLV" },
  { name: "Jinan Yaoqiang International Airport", city: "Jinan", cityCode: "TNA", airportCode: "TNA" },
  { name: "Ivato Airport", city: "Antananarivo", cityCode: "TNR", airportCode: "TNR" },
  { name: "Tromsø Airport, Langnes", city: "Tromso", cityCode: "TOS", airportCode: "TOS" },
  { name: "Tampa International Airport", city: "Tampa", cityCode: "TPA", airportCode: "TPA" },
  { name: "Taiwan Taoyuan International Airport", city: "Taipei", cityCode: "TPE", airportCode: "TPE" },
  { name: "Trondheim Airport, Værnes", city: "Trondheim", cityCode: "TRD", airportCode: "TRD" },
  { name: "Turin Airport", city: "Turin", cityCode: "TRN", airportCode: "TRN" },
  { name: "Trivandrum International Airport", city: "Thiruvananthapuram", cityCode: "TRV", airportCode: "TRV" },
  { name: "Tianjin Binhai International Airport", city: "Tianjin", cityCode: "TSN", airportCode: "TSN" },
  { name: "Tulsa International Airport", city: "Tulsa", cityCode: "TUL", airportCode: "TUL" },
  { name: "Tunis Carthage International Airport", city: "Tunis", cityCode: "TUN", airportCode: "TUN" },
  { name: "Taiyuan Wusu Airport", city: "Taiyuan", cityCode: "TYN", airportCode: "TYN" },
  { name: "Ulaanbaatar Chinggis Khaan International Airport", city: "Ulaanbaatar", cityCode: "ULN", airportCode: "UBN" },
  { name: "Ufa International Airport", city: "Ufa", cityCode: "UFA", airportCode: "UFA" },
  { name: "Mariscal Sucre International Airport", city: "Quito", cityCode: "UIO", airportCode: "UIO" },
  { name: "Hasanuddin International Airport", city: "Makassar", cityCode: "UPG", airportCode: "UPG" },
  { name: "Ürümqi Diwopu International Airport", city: "Urumqi", cityCode: "URC", airportCode: "URC" },
  { name: "Hewanorra International Airport", city: "St Lucia", cityCode: "SLU", airportCode: "UVF" },
  { name: "Varna Airport", city: "Varna", cityCode: "VAR", airportCode: "VAR" },
  { name: "Venice Marco Polo Airport", city: "Venice", cityCode: "VCE", airportCode: "VCE" },
  { name: "Vienna International Airport", city: "Vienna", cityCode: "VIE", airportCode: "VIE" },
  { name: "Vnukovo International Airport", city: "Moscow", cityCode: "MOW", airportCode: "VKO" },
  { name: "Bauerfield International Airport", city: "Port Vila", cityCode: "VLI", airportCode: "VLI" },
  { name: "Vilnius International Airport", city: "Vilnius", cityCode: "VNO", airportCode: "VNO" },
  { name: "Juan Gualberto Gomez International Airport", city: "Varadero", cityCode: "VRA", airportCode: "VRA" },
  { name: "Verona-Villafranca Valerio Catullo Airport", city: "Verona", cityCode: "VRN", airportCode: "VRN" },
  { name: "Viru Viru International Airport", city: "Santa Cruz", cityCode: "SRZ", airportCode: "VVI" },
  { name: "Vladivostok International Airport", city: "Vladivostok", cityCode: "VVO", airportCode: "VVO" },
  { name: "Warsaw Chopin Airport", city: "Warsaw", cityCode: "WAW", airportCode: "WAW" },
  { name: "Hosea Kutako International Airport", city: "Windhoek", cityCode: "WDH", airportCode: "WDH" },
  { name: "Wellington International Airport", city: "Wellington", cityCode: "WLG", airportCode: "WLG" },
  { name: "Wenzhou Longwan International Airport", city: "Wenzhou", cityCode: "WNZ", airportCode: "WNZ" },
  { name: "Wuhan Tianhe International Airport", city: "Wuhan", cityCode: "WUH", airportCode: "WUH" },
  { name: "Abudwak Airport", city: "Abudwak", cityCode: "XABW", airportCode: "XABW" },
  { name: "Afmadow Airport", city: "Afmadow", cityCode: "XAFM", airportCode: "XAFM" },
  { name: "Burdhubo Airport", city: "Burdhubo", cityCode: "XBDH", airportCode: "XBDH" },
  { name: "Buulo Burte Airport", city: "Buulo Burte", cityCode: "XBLB", airportCode: "XBLB" },
  { name: "Bu'aale Airport", city: "Bu'aale", cityCode: "XBLE", airportCode: "XBLE" },
  { name: "Beledxaawo Airport", city: "Beledxaawo", cityCode: "XBLX", airportCode: "XBLX" },
  { name: "Barawa Airport", city: "Barawa", cityCode: "XBRO", airportCode: "XBRO" },
  { name: "Buuhoodle Airport", city: "Buuhoodle", cityCode: "XBUH", airportCode: "XBUH" },
  { name: "Caabudwaaq Airport", city: "Caabudwaaq", cityCode: "XCBD", airportCode: "XCBD" },
  { name: "Doolow Airport", city: "Doolow", cityCode: "XDLW", airportCode: "XDLW" },
  { name: "Dhobley Airstrip", city: "Dhobley", cityCode: "XDLY", airportCode: "XDLY" },
  { name: "Diinsoor Airport", city: "Diinsoor", cityCode: "XDNS", airportCode: "XDNS" },
  { name: "Dhuusamarreeb Airport", city: "Dhuusamarreeb", cityCode: "XDSM", airportCode: "XDSM" },
  { name: "El Barde Airport", city: "Ceelbarde", cityCode: "XELB", airportCode: "XELB" },
  { name: "El Wak Airport", city: "El Wak", cityCode: "XELK", airportCode: "XELK" },
  { name: "Oshaca Airport (Mudug)", city: "Gaalkacayo", cityCode: "GLK", airportCode: "XGLK" },
  { name: "Xi'an Xianyang International Airport", city: "Xi An", cityCode: "SIA", airportCode: "XIY" },
  { name: "Jowhar Airport", city: "Jowhaar", cityCode: "XJHR", airportCode: "XJHR" },
  { name: "Merca Airport", city: "Merca", cityCode: "XMER", airportCode: "XMER" },
  { name: "Xiamen Gaoqi International Airport", city: "Xiamen", cityCode: "XMN", airportCode: "XMN" },
  { name: "Matabaan Airport", city: "Matabaan", cityCode: "XMTB", airportCode: "XMTB" },
  { name: "Wajid Airport", city: "Wajid", cityCode: "XWJD", airportCode: "XWJD" },
  { name: "Xudur Airport", city: "Xuddur", cityCode: "XXDR", airportCode: "XXDR" },
  { name: "Edmonton International Airport", city: "Edmonton", cityCode: "YEA", airportCode: "YEG" },
  { name: "Halifax / Stanfield International Airport", city: "Halifax", cityCode: "YHZ", airportCode: "YHZ" },
  { name: "Yantai Penglai International Airport", city: "Yantai", cityCode: "YNT", airportCode: "YNT" },
  { name: "Ottawa Macdonald-Cartier International Airport", city: "Ottawa", cityCode: "YOW", airportCode: "YOW" },
  { name: "Quebec Jean Lesage International Airport", city: "Quebec", cityCode: "YQB", airportCode: "YQB" },
  { name: "Montreal / Pierre Elliott Trudeau International Airport", city: "Montreal", cityCode: "YMQ", airportCode: "YUL" },
  { name: "Vancouver International Airport", city: "Vancouver", cityCode: "YVR", airportCode: "YVR" },
  { name: "Winnipeg / James Armstrong Richardson International Airport", city: "Winnipeg", cityCode: "YWG", airportCode: "YWG" },
  { name: "Calgary International Airport", city: "Calgary", cityCode: "YYC", airportCode: "YYC" },
  { name: "St. John's International Airport", city: "St Johns", cityCode: "YYT", airportCode: "YYT" },
  { name: "Lester B. Pearson International Airport", city: "Toronto", cityCode: "YTO", airportCode: "YYZ" },
  { name: "Zagreb Airport", city: "Zagreb", cityCode: "ZAG", airportCode: "ZAG" },
  { name: "Zhukovsky International Airport", city: "Moscow", cityCode: "MOW", airportCode: "ZIA" },
  { name: "Abeid Amani Karume International Airport", city: "Zanzibar", cityCode: "ZNZ", airportCode: "ZNZ" },
  { name: "Zürich Airport", city: "Zurich", cityCode: "ZRH", airportCode: "ZRH" },
  { name: "Laascaanood Airport", city: "Laascaanood", cityCode: "XLAS", airportCode: "XLAS" },
  { name: "Berbera Airport", city: "Berbara", cityCode: "BBO", airportCode: "BBO" },
  { name: "Garissa", city: "Garissa", cityCode: "GAS", airportCode: "GAS" },
  { name: "Jomo Kenyatta International Airport", city: "Nairobi", cityCode: "NBO", airportCode: "NBO" },
  { name: "Moyale", city: "Moyale", cityCode: "OYL", airportCode: "OYL" },
  { name: "Nairobi Wilson Airport", city: "Nairobi", cityCode: "NBO", airportCode: "WIL" },
  { name: "Beletwene Airport", city: "Beledweyne", cityCode: "BLW", airportCode: "BLW" },
  { name: "Abdullahi Yusuf Airport (Puntland)", city: "Gaalkacayo", cityCode: "GLK", airportCode: "GLK" },
  { name: "Guriel Airport", city: "Guriceel", cityCode: "XGRL", airportCode: "GUO" },
  { name: "Egal International Airport", city: "Hargesia", cityCode: "HGA", airportCode: "HGA" },
  { name: "Julius Nyerere International Airport", city: "Dar Es Salaam", cityCode: "DAR", airportCode: "DAR" },
  { name: "Burao Airport", city: "Burco", cityCode: "BUO", airportCode: "BUO" },
];
