import { Box, styled } from '@mui/material';
import { colors } from 'style';

export const CardWrapper = styled(Box)`
  padding: 24px 16px 18px 24px;
  height: 235px;
  background-color: ${colors.white};
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.2);
  border-right: 4px;
`;

export const CardTitle = styled(Box)`
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.black};
`;

export const CardSubtitle = styled(Box)`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: ${colors.gray60};
`;

export const ButtonsBlock = styled(Box)`
  display: flex;
  align-self: end;

  & .MuiButton-root {
    color: ${colors.secondary};
    min-height: 36px;
    padding: 10px 8px;
    font-weight: 500;
    line-height: 16px;
    font-size: 16px;
    text-transform: uppercase;
    &:nth-of-type(2) {
      margin-left: 24px;
    }
  }
`;
