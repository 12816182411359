import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, InputAdornment, Typography } from '@mui/material';
import { ButtonBlock, JustifyCenter } from 'style';
import { InputPhone, MarginTop } from 'components';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';

interface FormValues {
  phoneNumber: string;
}
type PropTypes = {
  onSubmitHandler: () => void;
  setContactNumber: (contactNumber: string) => void;
  paymentLogo?: string;
  network?: string;
};
export const MobilePaymentForm = ({ paymentLogo, onSubmitHandler, setContactNumber }: PropTypes) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  });

  const phoneNumber = useWatch({
    control,
    name: 'phoneNumber',
    defaultValue: '',
  });

  useEffect(() => {
    setContactNumber(phoneNumber || '');
  }, [phoneNumber]);

  const onSubmit = (values: { phoneNumber: string }) => {
    setContactNumber(values.phoneNumber);
    onSubmitHandler();
  };

  return (
    <MarginTop marginTop={'30'}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
        <Typography fontSize={16} lineHeight={'24px'} fontWeight={400}>
          Numberka aad ka so diray so
        </Typography>
        <MarginTop marginTop={'10'}>
          <InputPhone
            label="Phone Number"
            error={errors?.phoneNumber}
            control={control}
            name={`phoneNumber`}
            defaultCountry={'so'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" sx={{ p: 0 }}>
                  <img src={paymentLogo} alt={'payment logo'} />
                </InputAdornment>
              ),
            }}
          />
        </MarginTop>
        <ButtonBlock>
          <JustifyCenter>
            <Button type={'submit'} variant={'contained'}>
              SEND CODE
            </Button>
          </JustifyCenter>
        </ButtonBlock>
      </form>
    </MarginTop>
  );
};
