import React from 'react';
import { Loader, MarginTop } from 'components/index';
import Button from '@mui/material/Button';
import { JustifyCenter, PaperWrapper, colors } from 'style';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { routes } from 'routes';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';
import { PaymentMode, PaymentStatusEnum } from 'enum/enum';

import waafi from 'assets/img/waafi.png';
import edhabLogo from 'assets/img/edahab.png';
import visa from 'assets/img/visa.png';
import amex from 'assets/img/amex.png';
import mastercard from 'assets/img/mastercard.png';
import discover from 'assets/img/discover.png';
import stripe from 'assets/img/stripe.png';
import unionpay from 'assets/img/unionpay.png';
import jcb from 'assets/img/jcb.png';
import dinersclub from 'assets/img/dinersclub.png';

export const cardType = [
  { cardBrand: 'waafi', img: waafi },
  { cardBrand: 'visa', img: visa },
  { cardBrand: 'Edahab', img: edhabLogo },
  { cardBrand: 'mastercard', img: mastercard },
  { cardBrand: 'amex', img: amex },
  { cardBrand: 'discover', img: discover },
  { cardBrand: 'unionpay', img: unionpay },
  { cardBrand: 'jcb', img: jcb },
  { cardBrand: 'stripe', img: stripe },
  { cardBrand: 'dinersclub', img: dinersclub },
];

interface IProps {
  journeys: JourneyOfferModel[];
  associatedPayments: AssociatedPaymentModel[];
  paymentId: string;
}

export const PaymentSuccessful = ({ journeys, associatedPayments, paymentId }: IProps) => {
  const amount = journeys[0]?.priceTotal;
  const history = useHistory();
  const reviewOrderHandler = () => {
    history.push(routes.order);
  };

  const payment = associatedPayments?.filter(
    (payment) =>
      payment.paymentState === PaymentStatusEnum.SUCCEEDED ||
      payment.paymentState === PaymentStatusEnum.AUTHORIZED ||
      payment.paymentState === PaymentStatusEnum.PAID ||
      payment.paymentState === PaymentStatusEnum.COMPLETE,
  );

  const paymentCard = payment
    ? cardType?.filter((el) => el?.cardBrand === payment[0]?.cardBrand)[0]
    : {
        cardBrand: PaymentMode.STRIPE,
        img: stripe,
      };
  const card = paymentCard ? paymentCard : { cardBrand: PaymentMode.STRIPE, img: stripe };

  if (!associatedPayments.length) {
    return <Loader />;
  }

  const confirmationNumber = paymentId?.slice(-6).toUpperCase(); // make shorter confirmation number
  return (
    <>
      <JustifyCenter>
        <Typography fontSize={22} lineHeight={'24px'} fontWeight={300}>
          Thank you for your payment!
        </Typography>
      </JustifyCenter>
      <MarginTop marginTop={'30'}>
        <PaperWrapper>
          <JustifyCenter>
            <Typography fontSize={20} lineHeight={'24px'} fontWeight={500} color={colors.primary}>
              Payment Receipt
            </Typography>
          </JustifyCenter>
          <JustifyCenter>
            <MarginTop marginTop={'28'}>
              <img src={card.img} alt="logo" />{' '}
            </MarginTop>
          </JustifyCenter>
          <MarginTop marginTop={'30'}>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={400} color={colors.gray60}>
              Payment Total
            </Typography>
          </MarginTop>
          <MarginTop marginTop={'6'}>
            <Typography fontSize={20} lineHeight={'24px'} fontWeight={500}>
              ${amount}
            </Typography>
          </MarginTop>
          <MarginTop marginTop={'16'}>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={400} color={colors.gray60}>
              Last 4 Digits
            </Typography>
          </MarginTop>
          <MarginTop marginTop={'6'}>
            <Typography fontSize={20} lineHeight={'24px'} fontWeight={500}>
              {payment && <>{payment[0]?.cardLast4}</>}
            </Typography>
          </MarginTop>
          <MarginTop marginTop={'16'}>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={400} color={colors.gray60}>
              Confirmation Number
            </Typography>
          </MarginTop>
          <MarginTop marginTop={'6'}>
            <Typography fontSize={20} lineHeight={'24px'} fontWeight={500}>
              {confirmationNumber}
            </Typography>
          </MarginTop>
        </PaperWrapper>
      </MarginTop>
      <MarginTop marginTop={'42'}>
        <JustifyCenter>
          <Button variant={'contained'} onClick={reviewOrderHandler}>
            Review Order Details
          </Button>
        </JustifyCenter>
      </MarginTop>
    </>
  );
};
