import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { DirectionEnum } from 'enum/enum';

interface IInitialState {
  journeyOption: JourneyOfferModel | null;
  direction: DirectionEnum | null;
}

const initialState: IInitialState = {
  journeyOption: null,
  direction: null,
};

export const journeyOptionSlice = createSlice({
  name: 'journeyOptions',
  initialState,
  reducers: {
    setJourneyOptionDetails(state, action: PayloadAction<IInitialState>) {
      state.journeyOption = action.payload.journeyOption;
      state.direction = action.payload.direction;
    },
    resetJourneyOptionDetails(state) {
      state.journeyOption = null;
      state.direction = null;
    },
  },
});
export const { setJourneyOptionDetails, resetJourneyOptionDetails } = journeyOptionSlice.actions;
