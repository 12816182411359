import React from 'react';
import { JustifyCenter } from 'style';
import { useDispatch } from 'react-redux';
import { SummaryItem } from 'components/pages/summary/SummaryItem/SummaryItem';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import { ContentWrapper } from 'components/pages/options/FlightOptions/FlightOption/styles';
import { MarginTop, Itineraries } from 'components';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { useMutation } from '@apollo/client';
import { CREATE_FLIGHT_ORDER, CREATE_FLIGHT_ORDER_data, CREATE_FLIGHT_ORDER_vars } from 'mutations/createFlightOrder';
import { setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';

export const Summary = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [createFlightOrderGraph, { loading: isLoading }] = useMutation<CREATE_FLIGHT_ORDER_data, CREATE_FLIGHT_ORDER_vars>(CREATE_FLIGHT_ORDER);

  const { paymentPassengerInfo } = useAppSelector((state) => state.payment);
  const passengersList = useAppSelector((state) => state.booking.passengers);
  const passengersCount = useAppSelector((state) => state.search.passengers);
  const priceOfferId = useAppSelector((state) => state.priceOffer.priceOffer?.id);
  const itineraries = useAppSelector((state) => state.priceOffer.priceOffer?.itineraries);

  const goBackHandler = () => {
    history.push(routes.booking);
  };

  const createFlightHandler = async () => {
    const transformedPassengersList = passengersList.map((p) => {
      const copyPassenger = { ...p };
      delete copyPassenger.birthDay;
      delete copyPassenger.birthYear;
      delete copyPassenger.birthMonth;
      return { ...copyPassenger, nationality: p.nationality.value, gender: p.gender.value };
    });
    const createFlightOrderRes = await createFlightOrderGraph({
      variables: {
        journeyUUID: priceOfferId as string,
        adults: passengersCount.adults,
        children: passengersCount.children,
        infants: passengersCount.infants,
        passengers: transformedPassengersList,
        pnrPhoneNumber: paymentPassengerInfo?.pnrPhoneNumber as string,
        pnrEmail: paymentPassengerInfo?.pnrEmail as string,
      },
    });
    if (createFlightOrderRes.data) {
      dispatch(setFlightOrderDetails(createFlightOrderRes.data?.createFlightOrder));
    }
    if (createFlightOrderRes.data?.createFlightOrder.flightOrderId) {
      history.push(routes.entry);
    }
  };

  return (
    <>
      <Typography fontSize={18} lineHeight={'21px'} fontWeight={300}>
        Check Order Summary:
      </Typography>

      <>
        <MarginTop marginTop={'28'}>
          <ContentWrapper>
            {itineraries?.map((way, index: number) => {
              return (
                <Itineraries
                  key={way.id}
                  segments={way.segments}
                  isShowMoreDetails={true}
                  passengersCount={passengersList.length}
                  wayNumber={index + 1}
                />
              );
            })}
          </ContentWrapper>
        </MarginTop>
        <MarginTop marginTop={'28'}>
          {passengersList?.map((item, index: number) => {
            return <SummaryItem key={`${item.firstName}${item.lastName}`} {...item} passengerCount={index + 1} goBackHandler={goBackHandler} />;
          })}
        </MarginTop>
        <JustifyCenter>
          <Button variant={'contained'} onClick={createFlightHandler} disabled={isLoading} sx={{ position: 'relative' }}>
            {isLoading && <CircularProgress sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }} size={18} />}
            Continue To Payment
          </Button>
        </JustifyCenter>
      </>
    </>
  );
};
