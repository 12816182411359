import { useAppSelector } from 'hooks/useAppSelector';
import { TicketAgeType } from 'enum/enum';

const passengerTitle = {
  adults: 'Adult',
  children: 'Child',
  infants: 'Infant',
};
type PassengerKey = keyof typeof passengerTitle;

export const useBookingFormData = () => {
  const { paymentPassengerInfo } = useAppSelector((state) => state.payment);
  const { passengers } = useAppSelector((state) => state.booking);
  const { passengers: passengersCount } = useAppSelector((state) => state.search);
  const generatePassengerTitleByType = () => {
    const titles: TicketAgeType[] = [];
    const singleTitles: string[] = [];
    Object.entries(passengersCount).forEach(([key, value]) => {
      if (value > 0) {
        for (let i = 0; i < value; i++) {
          titles.push(`${key}` as TicketAgeType);
          singleTitles.push(`${passengerTitle[key as PassengerKey]} ${i + 1}`);
        }
      }
    });
    return {
      titles: titles,
      singleTitles: singleTitles,
    };
  };
  const passengerTitles = generatePassengerTitleByType();

  return {
    passengers,
    paymentPassengerInfo,
    passengerTitlesByType: passengerTitles.titles,
    passengerSingleTitle: passengerTitles.singleTitles,
  };
};
