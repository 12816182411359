import React from 'react';
import { Typography } from '@mui/material';
import { FlightOption } from 'components/pages/options/FlightOptions/FlightOption';
import { colors } from 'style';
import { Loader, MarginTop } from 'components';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { useGetRequestOptions } from 'components/pages/options/FlightOptions/useGetRequestOptions';
import { LoaderWrapper } from 'components/pages/options/FlightOptions/styles';
import { EmptyOptionsResult } from 'components/pages/options/FlightOptions/EmptyOptionsResult';

type PropsType = {
  goBackHandler: () => void;
};

export const FlightOptions = ({ goBackHandler }: PropsType) => {
  const { data, isLoading } = useGetRequestOptions();

  if (isLoading)
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );

  return (
    <>
      {data?.journeyOffers?.length ? (
        <Typography fontSize={18} fontWeight={300} lineHeight={'24px'} color={colors.black}>
          Flight Options:
        </Typography>
      ) : (
        <EmptyOptionsResult data={data} goBackHandler={goBackHandler} />
      )}

      {!!data?.journeyOffers.length && (
        <MarginTop marginTop={'20'}>
          {data.journeyOffers?.map((item: JourneyOfferModel) => {
            return <FlightOption key={item.id} {...item} />;
          })}
        </MarginTop>
      )}
    </>
  );
};
