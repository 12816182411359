import React from 'react';
import Typography from '@mui/material/Typography';
import { Header } from 'components';
import { colors } from 'style';
import { AboutPageComponents } from 'components/pages/about/AboutPageComponents';

export const AboutPage = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      About Us
    </Typography>
  );
  return (
    <>
      <Header content={headerContent} />
      <AboutPageComponents />
    </>
  );
};
