import React from 'react';
import { BoxSpaceBetween, FlexColumn, FlexRow, colors } from 'style';
import { IconButton, Typography } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { TypographyAdaptiveBold } from 'components/PassengeListItem/styles';
import { MarginBottom } from 'components';

type PropTypes = {
  passengerCount: number;
  isBackButton: boolean;
  firstName: string;
  lastName: string;
  pnrEmail: string | null;
  dateOfBirth?: string;
  gender?: string;
  passengerType?: string;
  goBackHandler?: () => void;
};

export const PassengerListItem = (props: PropTypes) => {
  const { passengerCount, isBackButton, goBackHandler, firstName, lastName, pnrEmail, dateOfBirth, gender, passengerType } = props;

  return (
    <>
      <BoxSpaceBetween>
        <Typography fontSize={18} lineHeight={'24px'} fontWeight={500} color={colors.primary}>
          Passenger {passengerCount}
        </Typography>
        {isBackButton && (
          <IconButton onClick={goBackHandler} sx={{ color: colors.primary }}>
            <ModeEditOutlineIcon />
          </IconButton>
        )}
      </BoxSpaceBetween>

      <FlexColumn marginBottom={'8px'}>
        <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
          Name
        </Typography>
        <TypographyAdaptiveBold>
          {firstName} {lastName}
        </TypographyAdaptiveBold>
      </FlexColumn>

      <FlexRow width={'100%'} justifyContent={'space-between'} flexWrap={'wrap'}>
        {pnrEmail && (
          <MarginBottom marginBottom={'14'}>
            <FlexColumn marginRight={'8px'}>
              <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
                Email
              </Typography>
              <TypographyAdaptiveBold>{pnrEmail}</TypographyAdaptiveBold>
            </FlexColumn>
          </MarginBottom>
        )}
        {passengerType && (
          <MarginBottom marginBottom={'14'}>
            <FlexColumn marginRight={'8px'}>
              <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
                Passenger Type
              </Typography>
              <TypographyAdaptiveBold>{passengerType}</TypographyAdaptiveBold>
            </FlexColumn>
          </MarginBottom>
        )}
        {dateOfBirth && (
          <MarginBottom marginBottom={'14'}>
            <FlexColumn marginRight={'8px'}>
              <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
                Date of Birth
              </Typography>
              <TypographyAdaptiveBold>{dateOfBirth}</TypographyAdaptiveBold>
            </FlexColumn>
          </MarginBottom>
        )}
        {gender && (
          <MarginBottom marginBottom={'14'}>
            <FlexColumn marginRight={'8px'}>
              <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
                Gender
              </Typography>
              <TypographyAdaptiveBold>{gender}</TypographyAdaptiveBold>
            </FlexColumn>
          </MarginBottom>
        )}
      </FlexRow>
    </>
  );
};
