import { gql } from '@apollo/client';

export const FLIGHT_ORDER_DETAILS = gql`
  query flightOrderDetails($flightOrderId: UUID, $PNR: String, $lastName: String) {
    flightOrderDetails(flightOrderId: $flightOrderId, PNR: $PNR, lastName: $lastName) {
      ... on OrderDetails {
        orderPaymentStatus
        orderPaymentStatus
        fasaxPNR
        pnrEmail
        externalPNRs {
          airlineName
          airlineCode
          externalPNR
        }
        associatedPayments {
          paymentId
          paymentState
          paymentCurrency
          paymentAmount
          cardBrand
          cardLast4
        }
        passengerCount
        passengers {
          firstName
          middleName
          lastName
          passengerType
        }
        journeys {
          id
          priceTotal
          farePerAdult
          farePerChild
          farePerInfant
          source
          brandedFare
          dateChange
          departureDate
          arrivalDate
          majorityCabin
          originCity {
            name
            code
          }
          originAirport {
            code
            name
            terminal
          }
          destinationCity {
            name
            code
          }
          destinationAirport {
            code
            name
            terminal
          }
          baggageAllowance {
            checkedDescription
            checkedWeight
            checkedQuantity
            checkedWeightUnit
            carryonDescription
            carryonWeight
            carryonQuantity
            carryonWeightUnit
          }
          ticketingDetails {
            codeshare
            description
            international
            multiTicket
            offerChanged
            priceConfirmed
            offerPriceTotal
            operatingAirlines {
              code
              name
            }
            paxPricings {
              paxId
              paxType
              fareTotal
            }
            ticketOffers {
              id
              itinerariesIncluded
              ticketPriceTotal
              validatingAirline {
                name
                code
              }
            }
          }

          itineraries {
            id
            duration
            ticketTransfer {
              changeOfAirport
              changeOfTerminal
              customsRequired
              description
              duration
              overnightStay
              sameOperatingAirline
              sameTicketingAirline
            }
            segments {
              id
              cabin
              flightinstanceId
              flightNumber
              connectionDetails {
                description
                duration
                changeOfTerminal
                changeOfAirport
                customsRequired
                overnightStay
              }
              technicalStop {
                customsRequired
                description
                duration
                stopAirport {
                  name
                  code
                  terminal
                }
              }
              originCity {
                name
                code
              }
              originAirport {
                name
                code
                terminal
              }
              destinationCity {
                name
                code
              }
              destinationAirport {
                code
                name
                terminal
              }
              departureAt
              arrivalAt
              operatingAirline {
                name
                code
              }
              validatingAirline {
                name
                code
              }
              originAirport {
                name
                code
              }
              duration
              fareCode
              aircraft {
                code
                description
              }
            }
          }
        }
      }
    }
  }
`;
