import React from 'react';
import { AutocompleteInput, Input, InputPhone, MarginTop, Select } from 'components';
import { Button, Typography } from '@mui/material';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { BookingFormHeader } from './BookingFormHeader';
import { defaultValues } from './defaultValues';
import {
  dateOfBirthDaysOptions,
  dateOfBirthMonthOptions,
  dateOfBirthYearsOptions,
  genderOptions,
} from 'components/pages/booking/BookFlightForm/constants';
import nationality from './nationality.json';
import { DateBirthdayBlock } from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'components/pages/booking/BookFlightForm/schema';
import { setBookingPassengersValues } from 'redux/slices/booking/booking-slice';
import { setPaymentPassengerInfo } from 'redux/slices/payment/payment-slice';
import { PassengerModel } from 'models/passenger.model';
import { useBookingFormData } from 'components/pages/booking/BookFlightForm/useBookingFormData';
import { routes } from 'routes';
import { useAppSelector } from 'hooks/useAppSelector';
import { regex } from 'validation/regex';

interface PropsType {
  passengersCount: number;
}

interface FormValues {
  passengers: PassengerModel[];
  pnrEmail: string;
  pnrPhoneNumber: string;
}

export const BookFlightForm = ({ passengersCount }: PropsType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { passengers } = useAppSelector((state) => state.booking);
  const { paymentPassengerInfo } = useAppSelector((state) => state.payment);

  const defaultPersistentValues = {
    passengers: passengers,
    pnrPhoneNumber: paymentPassengerInfo?.pnrPhoneNumber,
    pnrEmail: paymentPassengerInfo?.pnrEmail,
  };
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormValues>({
    defaultValues: passengers?.length ? defaultPersistentValues : defaultValues(passengersCount),
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  });
  const { fields } = useFieldArray({
    control,
    name: 'passengers',
  });
  const { passengerTitlesByType, passengerSingleTitle } = useBookingFormData();

  const onSubmit = (values: FormValues) => {
    const bookedPassengers = values.passengers.map((passenger: PassengerModel) => {
      const day = Number(passenger.birthDay) <= 9 ? `0${passenger.birthDay}` : passenger.birthDay;
      const dateOfBirth = `${passenger.birthYear}-${passenger.birthMonth}-${day}`;
      return { ...passenger, dateOfBirth: dateOfBirth };
    });

    dispatch(setBookingPassengersValues(bookedPassengers));
    dispatch(
      setPaymentPassengerInfo({
        pnrEmail: values.pnrEmail,
        pnrPhoneNumber: regex.parsePhoneNumber(values.pnrPhoneNumber),
      }),
    );
    history.push(routes.summary);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
      <MarginTop marginTop="32" key="pnr">
        <BookingFormHeader title={'Contact Information'} />
      </MarginTop>

      <MarginTop marginTop={'12'}>
        <Typography fontSize={18} lineHeight={'21px'} fontWeight={300}>
          Please enter passenger details:
        </Typography>
      </MarginTop>

      <MarginTop marginTop={'12'}>
        <InputPhone error={errors?.pnrPhoneNumber} control={control} name={`pnrPhoneNumber`} label={''} defaultCountry={'so'} />
      </MarginTop>

      <MarginTop marginTop={'12'}>
        <Input error={errors?.pnrEmail} control={control} name={`pnrEmail`} label={'Email*'} />
      </MarginTop>

      {fields.map((passengerFields, i) => {
        return (
          <React.Fragment key={passengerFields.id}>
            <MarginTop marginTop={'12'}>
              <BookingFormHeader title={passengerSingleTitle[i]} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.firstName} control={control} name={`passengers.${i}.firstName`} label={'First name*'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.middleName} control={control} name={`passengers.${i}.middleName`} label={'Middle name'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input error={errors?.passengers?.[i]?.lastName} control={control} name={`passengers.${i}.lastName`} label={'Last name*'} />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <AutocompleteInput
                error={errors?.passengers?.[i]?.gender as FieldError}
                control={control}
                name={`passengers.${i}.gender`}
                label={'Gender*'}
                options={genderOptions}
              />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <AutocompleteInput
                error={errors?.passengers?.[i]?.nationality as FieldError}
                control={control}
                name={`passengers.${i}.nationality`}
                label={'Nationality*'}
                options={nationality}
              />
            </MarginTop>
            <MarginTop marginTop={'12'}>
              <Input
                error={errors?.passengers?.[i]?.passportNumber}
                control={control}
                name={`passengers.${i}.passportNumber`}
                label={'Passport number*'}
              />
            </MarginTop>
            <DateBirthdayBlock>
              <Select
                error={errors?.passengers?.[i]?.birthYear}
                placeholder={'Year*'}
                fullWidth
                control={control}
                name={`passengers.${i}.birthYear`}
                label={''}
                options={dateOfBirthYearsOptions(passengerTitlesByType[i])}
                isCenterAutofocus={true}
              />
              <Select
                error={errors?.passengers?.[i]?.birthMonth}
                placeholder={'Month*'}
                fullWidth
                control={control}
                name={`passengers.${i}.birthMonth`}
                label={''}
                options={dateOfBirthMonthOptions}
              />
              <Select
                error={errors?.passengers?.[i]?.birthDay}
                placeholder={'Day*'}
                fullWidth
                control={control}
                name={`passengers.${i}.birthDay`}
                label={''}
                options={dateOfBirthDaysOptions}
              />
            </DateBirthdayBlock>
          </React.Fragment>
        );
      })}

      <MarginTop marginTop={'16'} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button type={'submit'} variant={'contained'}>
          Next: Confirm Booking
        </Button>
      </MarginTop>
    </form>
  );
};
