import { GenderEnum, TicketAgeType } from 'enum/enum';
// helpers for constants
const createArrIntervalNumbers = (start: number, end: number) => {
  return Array(end - start + 1)
    .fill(null)
    .map((_, idx) => {
      return {
        title: (start + idx).toString(),
        value: (start + idx).toString(),
      };
    });
};

const yearArrSince = 1920;
const yearArrFrom = Number(new Date().getFullYear());

export const genderOptions = [
  { title: 'Male', value: GenderEnum.MALE },
  { title: 'Female', value: GenderEnum.FEMALE },
];

export const dateOfBirthMonthOptions = [
  { title: 'January', value: '01' },
  { title: 'February', value: '02' },
  { title: 'March', value: '03' },
  { title: 'April', value: '04' },
  { title: 'May', value: '05' },
  { title: 'June', value: '06' },
  { title: 'July', value: '07' },
  { title: 'August', value: '08' },
  { title: 'September', value: '09' },
  { title: 'October', value: '10' },
  { title: 'November', value: '11' },
  { title: 'December', value: '12' },
];

export const dateOfBirthDaysOptions = createArrIntervalNumbers(1, 31);

export const dateOfBirthYearsOptions = (ageType: TicketAgeType) => {
  const yearsChildren = yearArrFrom - 11;
  const yearsInfants = yearArrFrom - 1;
  const yearsAdults = yearArrFrom - 16;
  if (ageType === TicketAgeType.INFANTS) {
    return createArrIntervalNumbers(yearsInfants, yearArrFrom);
  }
  if (ageType === TicketAgeType.CHILDREN) {
    return createArrIntervalNumbers(yearsChildren, yearArrFrom);
  }
  return createArrIntervalNumbers(yearArrSince, yearsAdults);
};

export const initialPassengersState = { adults: 0, children: 0, infants: 0 };
