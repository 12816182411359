import React, { ReactElement, useState } from 'react';
import { CircularProgress, Collapse, Typography } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { BoxSpaceBetween } from 'style';
import { convertShortDate } from 'utils/dates';
import { DirectionEnum } from 'enum/enum';
import { Itineraries } from 'components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  AccordionDetailsStyled,
  AccordionHeader,
  AccordionStyled,
  ContentWrapper,
  SelectButton,
  SelectButtonWrapper,
  TypographyAverage,
  PassengersInvoice,
} from './styles';
import { routes } from 'routes';
import { setJourneyOptionDetails } from 'redux/slices/journeyOption/journeyOption-slice';
import { useAppSelector } from 'hooks/useAppSelector';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { multipleOrSingleWordEnding } from 'utils/strings';
import { useLazyQuery } from '@apollo/client';
import { PRICE_OFFER } from 'queries/priceOffer';
import { setPriceOffer } from 'redux/slices/flightOffer/priceOffer-slice';

export const FlightOption = (props: JourneyOfferModel): ReactElement => {
  const [setPriceOfferRequest, { loading: isLoading }] = useLazyQuery(PRICE_OFFER);

  const [isShowMoreDetails, setIsShowMoreDetails] = useState(false);
  const { priceTotal, id, itineraries, farePerAdult } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { direction, passengers } = useAppSelector((state) => state.search);

  const passengersCount = Object.values(passengers).reduce((prev, current) => Number(prev) + Number(current), 0);
  const flightForward = itineraries[0].segments[0];
  const flightBackFrom = itineraries[1]?.segments[0];

  const onChangeDetails = () => {
    setIsShowMoreDetails((prev) => !prev);
  };

  const setOptionHandler = () => {
    dispatch(setJourneyOptionDetails({ journeyOption: props, direction }));
    history.push(routes.booking);
  };

  const setPriceOfferHandler = async () => {
    const flightOffers = await setPriceOfferRequest({
      variables: {
        journeyUUID: props.id,
      },
    });
    dispatch(setPriceOffer(flightOffers.data.priceOffer));
  };

  const confirmFlightOrder = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    await setPriceOfferHandler();
    setOptionHandler();
  };

  return (
    <>
      <AccordionStyled expanded={true} id={id} onChange={onChangeDetails} disableGutters={true} isShowMoreDetails={isShowMoreDetails}>
        <AccordionHeader expandIcon={<ExpandCircleDownIcon color="primary" />} id={id}>
          <BoxSpaceBetween>
            <div>
              <Typography fontSize={12} lineHeight={'24px'} fontWeight={400}>
                {flightForward.validatingAirline.name}
                {flightBackFrom && flightForward.validatingAirline.name !== flightBackFrom?.validatingAirline.name
                  ? `/ ${flightBackFrom.validatingAirline.name}`
                  : ''}
              </Typography>
              <Typography fontSize={12} lineHeight={'22px'} fontWeight={300}>
                {convertShortDate(flightForward.departureAt)} {flightBackFrom ? `/ ${convertShortDate(flightBackFrom.departureAt)}` : ''}
              </Typography>
            </div>
            <Typography fontSize={22} lineHeight={'24px'} mt={1.6} mr={0.5} fontWeight={500}>
              {farePerAdult && `$${farePerAdult}`}
            </Typography>
          </BoxSpaceBetween>
        </AccordionHeader>
        <AccordionDetailsStyled>
          <Collapse in={isShowMoreDetails} collapsedSize={direction === DirectionEnum.ONE_WAY ? 77 : 154}>
            <ContentWrapper variant={'outlined'}>
              {props.itineraries.map((way, index: number) => {
                return (
                  <Itineraries
                    key={way.id}
                    segments={way.segments}
                    isShowMoreDetails={isShowMoreDetails}
                    passengersCount={passengersCount}
                    wayNumber={index + 1}
                  />
                );
              })}
            </ContentWrapper>
          </Collapse>
        </AccordionDetailsStyled>
        {isShowMoreDetails && (
          <PassengersInvoice>
            <TypographyAverage>
              {passengersCount} {multipleOrSingleWordEnding('passenger', passengersCount > 1)} for Total ${priceTotal}
            </TypographyAverage>
          </PassengersInvoice>
        )}
        <SelectButtonWrapper>
          <SelectButton fullWidth onClick={confirmFlightOrder} disabled={isLoading}>
            {isLoading && <CircularProgress sx={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 0%)' }} size={18} />}
            Select
          </SelectButton>
        </SelectButtonWrapper>
      </AccordionStyled>
    </>
  );
};
