import React, { useEffect } from 'react';
import { Box, Modal as ModalMui, styled } from '@mui/material';
import { breakpoints, colors } from 'style';

const ModalStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  box-shadow: 0 0 10px 12px ${colors.boxShadow};
  @media (max-width: ${breakpoints.md}) {
    width: 80%;
  }
`;

type PropTypes = {
  open: boolean;
  onClose: (value: boolean) => void;
  children: React.ReactChild;
};

export const Modal = React.memo((props: PropTypes) => {
  const { open, onClose, children } = props;

  useEffect(() => {
    if (open) {
      document.querySelector('.woot-widget-bubble')?.classList.add('hide-chatwood');
    } else {
      document.querySelector('.woot-widget-bubble')?.classList.remove('hide-chatwood');
    }
  }, [open]);
  return (
    <ModalMui open={open} onClose={onClose}>
      <ModalStyled>{children}</ModalStyled>
    </ModalMui>
  );
});
