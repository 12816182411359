import { styled } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';

export const InputPhoneNumber = styled(MuiPhoneNumber)`
  //made hardcoded styles because this lib has bug with safari and firefox
  & .MuiPhoneNumber-flagButton {
    min-width: min-content !important;
    height: 30px !important;
    width: 30px !important;
    & svg {
      width: 30px !important;
      height: 16px !important;
    }
  }
`;
