import React from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { MenuItem, SelectProps } from '@mui/material';
import { Error, MuiSelect } from 'components/pages/home/SelectHome/styles';

interface IOption {
  title: string;
  value: string | number;
}

export interface SelectType extends Omit<SelectProps, 'name' | 'error'> {
  control: Control<any>;
  name: string;
  options: IOption[];
  error?: FieldError;
  onChangeSelect?: () => void;
}

export const SelectHome = ({ control, name, options, error, onChangeSelect, ...props }: SelectType) => {
  return (
    <Controller
      defaultValue={''}
      control={control}
      name={name}
      render={({ field: { value, onChange, ...other } }) => {
        return (
          <>
            <MuiSelect
              {...other}
              {...props}
              variant={'filled'}
              error={!!error}
              value={value}
              onChange={(e) => {
                onChange(e);
                if (onChangeSelect) {
                  onChangeSelect();
                }
              }}
              displayEmpty={true}
            >
              {options.map(({ title, value }) => (
                <MenuItem key={title} value={value} style={{ whiteSpace: 'unset' }}>
                  {title}
                </MenuItem>
              ))}
            </MuiSelect>
            {error && <Error>{error?.message}</Error>}
          </>
        );
      }}
    />
  );
};
