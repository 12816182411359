import React from 'react';
import { ButtonsBlock, ContentWrapper } from 'components/pages/order/OrderSummary/styles';
import { BoxSpaceBetween, PaperItemWrapper } from 'style';
import { Button, Typography } from '@mui/material';
import { PaymentBlock } from 'components/pages/order/PaymentBlock/PaymentBlock';
import { PassengerListItem, Itineraries, MarginTop } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { routes } from 'routes';
import { useHistory } from 'react-router-dom';
import { resetSearchState } from 'redux/slices/search/search-slice';

export const OrderSummary = () => {
  const { journeys, passengers, associatedPayments, externalPNRs, passengerCount, pnrEmail, fasaxPNR } = useAppSelector((state) => state.flightOrder);
  const dispatch = useDispatch();
  const history = useHistory();

  const goToNewSearch = () => {
    dispatch(resetSearchState());
    history.push(routes.home);
  };

  const bookHotel = () => {
    const bookingUrl = 'https://www.booking.com';
    window.open(bookingUrl, '_blank');
  };

  return (
    <>
      {externalPNRs && (
        <BoxSpaceBetween sx={{ alignItems: 'center' }}>
          <Typography fontSize={16} lineHeight={'21px'} fontWeight={300}>
            {externalPNRs[0].airlineName} PNR:
          </Typography>
          <Typography fontSize={24} lineHeight={'36px'} fontWeight={400}>
            {externalPNRs[0].externalPNR}
          </Typography>
        </BoxSpaceBetween>
      )}
      <BoxSpaceBetween sx={{ alignItems: 'center' }}>
        <Typography fontSize={16} lineHeight={'21px'} fontWeight={300}>
          Fasax Confirmation Code:
        </Typography>
        <Typography fontSize={24} lineHeight={'36px'} fontWeight={400}>
          {fasaxPNR}
        </Typography>
      </BoxSpaceBetween>
      <ContentWrapper>
        {journeys[0].itineraries.map((way, index) => {
          return (
            <Itineraries
              key={way.id}
              segments={way.segments}
              isShowMoreDetails={true}
              passengersCount={passengerCount as number}
              wayNumber={index + 1}
            />
          );
        })}
        <PaperItemWrapper>
          {passengers.map((passenger, index) => {
            return (
              <MarginTop marginTop={index >= 1 ? '24' : '0'}>
                <PassengerListItem
                  passengerCount={index + 1}
                  isBackButton={false}
                  firstName={passenger.firstName}
                  lastName={passenger.lastName}
                  pnrEmail={pnrEmail}
                  dateOfBirth={passenger.dateOfBirth}
                  passengerType={passenger.passengerType}
                />
              </MarginTop>
            );
          })}
        </PaperItemWrapper>

        <PaymentBlock associatedPayments={associatedPayments} />
      </ContentWrapper>
      <ButtonsBlock>
        <Button variant="contained" onClick={goToNewSearch}>
          Search more flights
        </Button>
        <Button onClick={bookHotel}>Book hotel</Button>
      </ButtonsBlock>
    </>
  );
};
