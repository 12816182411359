import React from 'react';
import { Header } from 'components';
import { Typography } from '@mui/material';
import { colors } from 'style';

export const BlogPage = () => {
  const headerContent = (
    <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
      Blog
    </Typography>
  );

  // eslint-disable-next-line jsx-a11y/iframe-has-title
  const blogContent = <iframe title="blog" id="fasaxBlog" width="100%" style={{ height: 'calc(100vh - 80px)' }} src="https://blog.fasax.com" />;
  return (
    <>
      <Header content={headerContent} />
      {blogContent}
    </>
  );
};
