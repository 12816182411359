import { FormHelperText } from '@mui/material';
import { Select } from '@mui/material';

import styled from '@emotion/styled';
import { breakpoints } from 'style/vars/breakpoints';
import { colors } from 'style';

export const Error = styled(FormHelperText)`
  color: ${colors.error};
  font-size: 12px;
  line-height: 16.6px;
  width: max-content;
  margin: 3px 14px 0;
`;

export const MuiSelect = styled(Select)`
  .MuiSelect-select {
    width: 150px;
    padding: 6px 0 10px 6px;
    background-color: white;
    @media (max-width: ${breakpoints.sm}) {
      width: 110px;
    }
  }

  &:before {
    border-bottom: 1px solid rgba(33, 33, 33, 0.08);
    bottom: 3px;
  }

  &:after,
  &:focus,
  &::selection {
    border-bottom: none;
    background-color: unset;
  }
`;

export const Placeholder = styled('span')`
  color: ${colors.placeholder};
`;
