import React, { ReactElement } from 'react';
import { FlexColumn, FlexRow, WidthBox20, WidthBox30, WidthBox40, WidthBox50 } from 'style';
import { convertHoursMinutes, convertDayShortDate, getTimeDifference } from 'utils/dates';
import { TicketClassEnum } from 'enum/enum';
import { Typography } from '@mui/material';
import { AirlineLogo, DotMarker, DurationFlightBox, DurationItem, ItemRow, TypographyCode, TypographyTime } from './styles';
import { SegmentModel } from 'models/segment.model';

type PropsType = {
  way: SegmentModel;
  width: number;
  ticketClass: TicketClassEnum;
  passengersCount: number;
  segments: SegmentModel[];
  logo: string;
  directionName: string;
};

export const DetailFlightOption = React.memo((props: PropsType): ReactElement => {
  const { way, ticketClass, passengersCount, segments, logo, directionName } = props;

  return (
    <>
      <ItemRow marginBottom={'16px'} position={'relative'}>
        <WidthBox40>
          <FlexColumn>
            <Typography fontSize={18} lineHeight={'24px'} fontWeight={500}>
              {directionName}
            </Typography>
            <Typography fontSize={14} lineHeight={'12px'} fontWeight={100}>
              {convertDayShortDate(segments[0].departureAt)}
            </Typography>
            <FlexRow flexWrap={'wrap'}>
              <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
                {ticketClass}
              </Typography>
              <DotMarker />
              <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
                {passengersCount} {passengersCount > 1 ? 'Passengers' : 'Passenger'}
              </Typography>
            </FlexRow>
          </FlexColumn>
        </WidthBox40>
        <WidthBox40>
          <FlexColumn>
            <TypographyCode>flight {way.flightNumber}</TypographyCode>
            <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
              {way.aircraft.description}
            </Typography>
          </FlexColumn>
        </WidthBox40>
        <WidthBox20 display={'flex'} justifyContent={'end'} alignItems={'center'}>
          <AirlineLogo>
            <img src={logo} alt={'logo'} />
          </AirlineLogo>
        </WidthBox20>
      </ItemRow>

      <ItemRow marginBottom={'4px'}>
        <WidthBox50>
          <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
            {segments[0].originAirport.name}
          </Typography>
        </WidthBox50>
        <WidthBox20>
          <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
            {segments[0].originAirport.code}
          </Typography>
        </WidthBox20>
        <WidthBox30 display={'flex'} justifyContent={'end'} alignItems={'end'}>
          <TypographyTime>{convertHoursMinutes(segments[0].departureAt)}</TypographyTime>
        </WidthBox30>
      </ItemRow>
      <DurationFlightBox>
        {segments.length === 1 ? (
          <DurationItem>
            <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
              {' '}
              Direct Flight
            </Typography>
            <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
              {getTimeDifference(way.departureAt, way.arrivalAt)}
            </Typography>
          </DurationItem>
        ) : (
          segments.slice(0, -1).map((segment, i: number) => {
            const durationFlightTime = getTimeDifference(segments[0].departureAt, segments[segments.length - 1].arrivalAt);

            return (
              <DurationItem key={segment.id}>
                <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
                  {i + 1} {i > 1 ? 'stops' : 'stop'} at {segment.destinationAirport.code}
                </Typography>
                <Typography fontSize={12} lineHeight={'24px'} fontWeight={300}>
                  {durationFlightTime}
                </Typography>
              </DurationItem>
            );
          })
        )}
      </DurationFlightBox>
      <ItemRow marginTop={'4px'}>
        <WidthBox50>
          <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
            {segments[segments.length - 1].destinationAirport.name}
          </Typography>
        </WidthBox50>
        <WidthBox20>
          <Typography fontSize={14} lineHeight={'24px'} fontWeight={300}>
            {segments[segments.length - 1].destinationAirport.code}
          </Typography>
        </WidthBox20>
        <WidthBox30 display={'flex'} justifyContent={'end'}>
          <TypographyTime>{convertHoursMinutes(segments[segments.length - 1].arrivalAt)}</TypographyTime>
        </WidthBox30>
      </ItemRow>
    </>
  );
});
