import { gql } from '@apollo/client';
import { PassengerModelBackendFormat } from 'models/passenger.model';
import { FlightOrder } from 'redux/slices/flightOrder/flightOrder-slice';

export interface CREATE_FLIGHT_ORDER_data {
  createFlightOrder: FlightOrder;
}

export interface CREATE_FLIGHT_ORDER_vars {
  journeyUUID: string;
  adults: number;
  children: number;
  infants: number;
  passengers: PassengerModelBackendFormat[];
  pnrPhoneNumber: string;
  pnrEmail: string;
}

export const CREATE_FLIGHT_ORDER = gql`
  mutation createFlightOrder(
    $journeyUUID: String!
    $adults: Int!
    $children: Int!
    $infants: Int!
    $passengers: [PassengerInput!]!
    $pnrPhoneNumber: String!
    $pnrEmail: String
  ) {
    createFlightOrder(
      orderDetails: {
        journeyUUID: $journeyUUID
        adults: $adults
        children: $children
        infants: $infants
        passengers: $passengers
        pnrPhoneNumber: $pnrPhoneNumber
        pnrEmail: $pnrEmail
      }
    ) {
      ... on OrderDetails {
        flightOrderId
        paymentState
        fasaxPNR
        externalPNRs {
          airlineCode
          airlineName
          externalPNR
        }
      }
      ... on OrderDetails {
        flightOrderId
        paymentState
        fasaxPNR
        externalPNRs {
          airlineCode
          airlineName
          externalPNR
        }
        associatedPayments {
          cardBrand
          cardLast4
          paymentAmount
          paymentCurrency
          paymentId
          paymentMode
          paymentState
        }
        orderPaymentStatus
        journeys {
          addedServices {
            description
            id
            offerId
            paxRef
            segRef
            serviceFee
            serviceFeeCurrency
          }
          arrivalDate
          brandedFare
          dateChange
          departureDate
          destinationAirport {
            code
            terminal
            name
          }
          baggageAllowance {
            carryonDescription
            carryonQuantity
            carryonWeight
            carryonWeightUnit
            checkedDescription
            checkedQuantity
            checkedWeightUnit
            checkedWeight
          }
          source
          priceTotal
          originCity {
            code
            name
          }
          originAirport {
            code
            name
            terminal
          }
          majorityCabin
          itineraries {
            addedServices {
              description
              serviceFeeCurrency
              serviceFee
              paxRef
              offerId
              id
              segRef
            }
            duration
            id
          }
          id
          farePerChild
          farePerAdult
          farePerInfant
          destinationCity {
            code
            name
          }
          ticketingDetails {
            codeshare
            description
            international
            multiAirline
            multiTicket
            offerPriceTotal
            priceConfirmed
            paxPricings {
              fareTotal
              paxId
              paxType
            }
            operatingAirlines {
              name
              code
            }
            offerChanged
            validatingAirlines {
              name
              code
            }
            ticketOffers {
              offerChanged
              id
              itinerariesIncluded
              paxPricings {
                fareTotal
                paxType
                paxId
                services {
                  description
                  serviceFee
                  segRef
                  paxRef
                  offerId
                  id
                  serviceFeeCurrency
                }
              }
              priceConfirmed
              source
              ticketPriceTotal
              validatingAirline {
                name
                code
              }
            }
          }
        }
        passengerCount
        passengers {
          firstName
          lastName
          passengerType
          middleName
        }
        pnrPhoneNumber
        pnrEmail
      }
    }
  }
`;
