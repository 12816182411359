import React, { useEffect } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import {
  Home,
  FlightOptionsPage,
  BookFlightPage,
  SummaryPage,
  PaymentEntryPage,
  PaymentApprovalPage,
  PaymentSuccessfulPage,
  OrderSummaryPage,
  AboutPage,
  BlogPage,
  PrivacyTerms,
  HelpPage,
  MyTripsPage,
  PageNotFound,
} from 'pages';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import { ChatwootBubble, Footer, Toaster, TTLModal } from 'components';
import ReactGA from 'react-ga';
import { colors } from 'style';
import { routes } from 'routes';
import { runApolloClientConfig, runAppConfig } from 'pages/config';

runAppConfig();

export const App = () => {
  const client = runApolloClientConfig();
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.yellow,
        dark: colors.black,
      },
    },
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Toaster />
          <CssBaseline>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <TTLModal />
                <Switch>
                  <Route exact path={routes.home} component={Home} />
                  <Route path={routes.options} component={FlightOptionsPage} />
                  <Route path={routes.booking} component={BookFlightPage} />
                  <Route path={routes.summary} component={SummaryPage} />
                  <Route path={routes.entry} component={PaymentEntryPage} />
                  <Route path={routes.approval} component={PaymentApprovalPage} />
                  <Route path={routes.success} component={PaymentSuccessfulPage} />
                  <Route path={routes.order} component={OrderSummaryPage} />
                  <Route path={routes.about} component={AboutPage} />
                  <Route path={routes.privacyTerms} component={PrivacyTerms} />
                  <Route path={routes.help} component={HelpPage} />
                  <Route path={routes.mytrips} component={MyTripsPage} />
                  <Route path={routes.blog} component={BlogPage} />
                  <Route path={routes['404']} component={PageNotFound} />
                  <Redirect to={routes['404']} />
                </Switch>
              </BrowserRouter>
              <ChatwootBubble />
              <Footer />
            </ThemeProvider>
          </CssBaseline>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};
