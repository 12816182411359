import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, IconButton, List, ListItemText, Typography } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PersonIcon from '@mui/icons-material/Person';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useWindowSize } from 'hooks/useWindowWidth';
import { FlexRow } from 'style';
import { DoneButtonBlock, ListItemStyled, PassengersDropdown } from 'components/pages/home/PassengersCountDrawer/styles';
import { useClickOutside } from 'hooks/useClickOutside';
import { InputHome } from 'components/pages/home/InputHome';
import { multipleOrSingleWordEnding } from 'utils/strings';
import { useAppSelector } from 'hooks/useAppSelector';
import { PassengerCountModel } from 'models/passenger.model';

type PropsType = {
  handlePassengers: (obj: PassengerCountModel) => void;
};

type PassengerCountType = {
  title: string;
  subtitle: string;
  count: number;
};

enum PassengerTitleEnum {
  ADULT = 'Adults',
  CHILDREN = 'Children',
  LAP_INFANTS = 'Lap Infants',
}

export const PassengersCountDrawer = ({ handlePassengers }: PropsType) => {
  const size = useWindowSize();
  const width = size.width;

  const { passengers: passengersRedux } = useAppSelector((state) => state.search);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [adultsCount, setAdultsCount] = useState<number>(passengersRedux.adults || 1);
  const [children, setChildren] = useState<number>(passengersRedux.children || 0);
  const [infants, setInfants] = useState<number>(passengersRedux.infants || 0);

  const passengersCount = adultsCount + children + infants;

  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  useEffect(() => {
    handlePassengers({
      adults: adultsCount,
      children: children,
      infants: infants,
    });
  }, [adultsCount, children, infants]);

  const passengersListCount = [
    { title: PassengerTitleEnum.ADULT, subtitle: '', count: adultsCount, isAllowed: true },
    { title: PassengerTitleEnum.CHILDREN, subtitle: '(ages 2-12 yr)', count: children },
    { title: PassengerTitleEnum.LAP_INFANTS, subtitle: '(ages 0-2 yr)', count: infants },
  ];

  const handleAddCount = (type: string, operation: string) => {
    switch (type) {
      case PassengerTitleEnum.ADULT: {
        return setAdultsCount((prevValue) => (operation === '+' ? prevValue + 1 : prevValue - 1));
      }
      case PassengerTitleEnum.CHILDREN: {
        return setChildren((prevValue) => (operation === '+' ? prevValue + 1 : prevValue - 1));
      }
      case PassengerTitleEnum.LAP_INFANTS: {
        return setInfants((prevValue) => (operation === '+' ? prevValue + 1 : prevValue - 1));
      }
    }
  };

  const passengerRef = useClickOutside(() => {
    setIsOpen(false);
  });

  const preventClosingPassengers = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const isDisablePlusButton = (item: PassengerCountType) => {
    return (
      (adultsCount === 0 && item.title !== PassengerTitleEnum.ADULT) ||
      passengersCount >= 6 ||
      (adultsCount < infants + 1 && item.title === PassengerTitleEnum.LAP_INFANTS)
    );
  };

  return (
    <>
      <Box sx={{ position: 'relative' }} onClick={toggleHandler} ref={passengerRef}>
        <InputHome
          id="filled-start-adornment"
          placeholder={''}
          value={passengersCount + ' ' + multipleOrSingleWordEnding('Passenger', passengersCount > 1)}
          startIcon={<PersonIcon />}
          endIcon={<ModeEditOutlineIcon />}
        />
        {isOpen ? (
          <PassengersDropdown className={'passenger'} onClick={preventClosingPassengers}>
            <List>
              {passengersListCount.map((item: PassengerCountType) => {
                const disabledDecrementPassenger = item.title === PassengerTitleEnum.ADULT ? item.count <= 1 : item.count === 0;
                return (
                  <ListItemStyled
                    key={item.title}
                    secondaryAction={
                      <ButtonGroup>
                        <IconButton
                          edge="start"
                          aria-label="delete"
                          onClick={() => handleAddCount(item.title, '-')}
                          disabled={disabledDecrementPassenger}
                        >
                          <RemoveCircleOutlineRoundedIcon />
                        </IconButton>
                        <FlexRow display={'flex'} justifyContent={'center'} alignItems={'center'}>
                          <Typography variant={'body1'}>{item.count}</Typography>
                        </FlexRow>

                        <IconButton edge="end" aria-label="plus" disabled={isDisablePlusButton(item)} onClick={() => handleAddCount(item.title, '+')}>
                          <AddCircleRoundedIcon />
                        </IconButton>
                      </ButtonGroup>
                    }
                  >
                    <ListItemText primary={item.title} sx={{ maxWidth: 'max-content', mr: 0.1 }} />
                    {width >= 480 ? <ListItemText secondary={item.subtitle} sx={{ mr: 1 }} /> : null}
                  </ListItemStyled>
                );
              })}
            </List>
            <DoneButtonBlock>
              <Button variant={'contained'} onClick={handleClose}>
                Done
              </Button>
            </DoneButtonBlock>
          </PassengersDropdown>
        ) : null}
      </Box>
    </>
  );
};
