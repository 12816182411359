import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JourneyOfferModel } from 'models/journeyOption.model';
import { ExternalPnr } from 'models/flightOrder.model';
import { PassengerModel } from 'models/passenger.model';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';

export interface FlightOrder {
  flightOrderId: string | null;
  paymentState: string | null;
  fasaxPNR: string | null;
  externalPNRs: ExternalPnr[];
  associatedPayments: AssociatedPaymentModel[];
  orderPaymentStatus: string | null;
  journeys: JourneyOfferModel[];
  passengerCount: number | null;
  passengers: Pick<PassengerModel, 'firstName' | 'lastName' | 'middleName' | 'passengerType' | 'dateOfBirth' | 'gender'>[];
  pnrPhoneNumber: string | null;
  pnrEmail: string | null;
}

const initialState: FlightOrder = {
  flightOrderId: null,
  paymentState: null,
  fasaxPNR: null,
  externalPNRs: [],
  associatedPayments: [],
  orderPaymentStatus: null,
  journeys: [],
  passengerCount: null,
  passengers: [],
  pnrPhoneNumber: null,
  pnrEmail: null,
};

export const flightOrderSlice = createSlice({
  name: 'flightOrder',
  initialState,
  reducers: {
    setFlightOrderDetails(state, action: PayloadAction<FlightOrder>) {
      state.flightOrderId = action.payload.flightOrderId;
      state.paymentState = action.payload.paymentState;
      state.fasaxPNR = action.payload.fasaxPNR;
      state.externalPNRs = action.payload.externalPNRs;
      state.associatedPayments = action.payload.associatedPayments;
      state.orderPaymentStatus = action.payload.orderPaymentStatus;
      state.journeys = action.payload.journeys;
      state.passengerCount = action.payload.passengerCount;
      state.passengers = action.payload.passengers;
      state.pnrPhoneNumber = action.payload.pnrPhoneNumber;
      state.pnrEmail = action.payload.pnrEmail;
    },
    setSuccessfulOrder(state, action) {
      state.orderPaymentStatus = action.payload.orderPaymentStatus;
      state.associatedPayments = action.payload.associatedPayments;
      state.journeys = action.payload.journeys;
    },
    resetFlightOrderState(state) {
      state = initialState;
    },
  },
});
export const { setFlightOrderDetails, resetFlightOrderState, setSuccessfulOrder } = flightOrderSlice.actions;
