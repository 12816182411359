import * as yup from 'yup';
import { array, object } from 'yup';
import { validation } from 'validation/validation';
import { errorMessages } from 'validation/errorMessages';
const autocompleteOptionShape = {
  title: validation.string,
  value: validation.string,
};
const passengerShape = {
  birthDay: validation.string,
  birthMonth: validation.string,
  birthYear: validation.string,
  dateOfBirth: validation.stringNotRequired,
  firstName: validation.stringWithoutNumbers,
  middleName: validation.stringWithoutNumbersNotRequired,
  lastName: validation.stringWithoutNumbers,
  nationality: object().shape(autocompleteOptionShape).typeError(errorMessages.required),
  passengerType: validation.stringNotRequired,
  passportNumber: validation.string,
  gender: object().shape(autocompleteOptionShape).typeError(errorMessages.required),
};

export const schema = yup.object().shape({
  passengers: array().of(object().shape(passengerShape)),
  pnrPhoneNumber: validation.phoneNumber(undefined),
  pnrEmail: validation.email,
});
