import React, { useEffect } from 'react';
import { colors, ContainerStyled } from 'style';
import { PaymentSuccessful } from 'components/pages/success/PaymentSuccessful';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { FLIGHT_ORDER_DETAILS } from 'queries/flightOrderDetails';
import { Typography } from '@mui/material';
import { Header } from 'components/Header';
import { useAppSelector } from 'hooks/useAppSelector';
import { Loader } from 'components/Loader';
import { LoaderWrapper } from 'components/pages/success/styles';
import { setSuccessfulOrder } from 'redux/slices/flightOrder/flightOrder-slice';

export const PaymentSuccessfulPageComponent = () => {
  const dispatch = useDispatch();
  const { flightOrderId, journeys, associatedPayments } = useAppSelector((state) => state.flightOrder);
  const paymentId = useAppSelector((state) => state.payment.paymentStripe?.paymentId);

  const [getFlightOrderDetails, { loading }] = useLazyQuery(FLIGHT_ORDER_DETAILS, {
    variables: {
      flightOrderId,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (flightOrderDetailsData) => {
      const successfulData = {
        orderPaymentStatus: flightOrderDetailsData.flightOrderDetails.orderPaymentStatus,
        associatedPayments: flightOrderDetailsData.flightOrderDetails.associatedPayments,
        journeys: flightOrderDetailsData.flightOrderDetails.journeys,
      };
      dispatch(setSuccessfulOrder(successfulData));
    },
  });

  useEffect(() => {
    getFlightOrderDetails();
  }, []);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      <Header
        content={
          <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
            Payment Successful!
          </Typography>
        }
      />
      <ContainerStyled>
        <PaymentSuccessful journeys={journeys} associatedPayments={associatedPayments} paymentId={paymentId as string} />
      </ContainerStyled>
    </>
  );
};
