import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DirectionEnum } from 'enum/enum';
import { DatepickerRanged, DatepickerSingle, SelectionDateWrapper } from './styles';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Modal } from 'components';
import { multipleOrSingleWordEnding } from 'utils/strings';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import { InputHome } from 'components/pages/home/InputHome';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';
import { SearchModel } from 'models/search.model';
import { useRequestRecommendationDates } from './useRequestRecommendationDates';

interface PropTypes {
  control: Control<any>;
  name: string;
  direction: DirectionEnum;
  inputValue: string | string[];
  error: boolean;
  setValue: UseFormSetValue<FieldValues>;
  index: number;
  returnDate: string | null;
  clearErrors: UseFormClearErrors<SearchModel>;
  arrivalAirport: string;
  departureAirport: string;
}

interface CalendarValue {
  value: null | Date;
}

export const SelectionDates = ({
  control,
  name,
  direction,
  inputValue,
  error,
  setValue,
  index,
  returnDate,
  clearErrors,
  departureAirport,
  arrivalAirport,
}: PropTypes) => {
  const isMulti = direction === DirectionEnum.MULTI;
  const isOneWay = direction === DirectionEnum.ONE_WAY;
  const isRoundWay = direction === DirectionEnum.ROUND;
  const [isOpenCalendarSingle, setIsOpenCalendarSingle] = useState<boolean>(false);
  const [isOpenCalendarRange, setIsOpenCalendarRange] = useState<boolean>(false);
  const currentDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(currentDate);

  const { renderRangePickerDay, renderSinglePickerDay } = useRequestRecommendationDates({ arrivalAirport, departureAirport, currentMonth });

  const setCurrentMonthHandler = (curr: Date) => {
    setCurrentMonth(curr);
  };

  const closeRangeCalendarRange = () => {
    setIsOpenCalendarRange(false);
  };

  const closeRangeCalendarSingle = () => {
    setIsOpenCalendarSingle(false);
  };

  const onSelectionDateClick = () => {
    return isOneWay || isMulti ? setIsOpenCalendarSingle(true) : setIsOpenCalendarRange(true);
  };

  const toggleCalendarModal = () => {
    setIsOpenCalendarSingle(!isOpenCalendarSingle);
  };

  return (
    <>
      <SelectionDateWrapper onClick={onSelectionDateClick}>
        <InputHome
          hasError={error}
          placeholder={multipleOrSingleWordEnding('Select Travel Date', isRoundWay)}
          value={inputValue}
          onClickIcon={toggleCalendarModal}
          startIcon={<DateRangeIcon />}
          endIcon={<ModeEditOutlineIcon />}
        />
      </SelectionDateWrapper>
      <Modal
        open={isOneWay || isMulti ? isOpenCalendarSingle : isOpenCalendarRange}
        onClose={isOneWay || isMulti ? closeRangeCalendarSingle : closeRangeCalendarRange}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            name={name}
            render={({ field: { ...rest } }) => {
              return !isRoundWay ? (
                <DatepickerSingle
                  disabled={true}
                  {...rest}
                  renderDay={renderSinglePickerDay}
                  orientation={'portrait'}
                  disableHighlightToday={true}
                  disablePast={true}
                  open={isOpenCalendarSingle}
                  onClose={closeRangeCalendarSingle}
                  renderInput={() => <></>}
                  onMonthChange={setCurrentMonthHandler}
                />
              ) : (
                <DatepickerRanged
                  disabled={true}
                  {...rest}
                  renderDay={renderRangePickerDay}
                  disableHighlightToday={true}
                  disablePast={true}
                  value={[rest.value ? rest.value : null, returnDate]}
                  onChange={(value: RangeInput<CalendarValue[]>) => {
                    setValue(name, value[0]);
                    setValue(`ItineraryRequest.${index}.returnDate`, value[1]);
                    if (value[1]) {
                      clearErrors(`ItineraryRequest.${index}.returnDate`);
                    }
                  }}
                  open={isOpenCalendarRange}
                  onClose={closeRangeCalendarRange}
                  onBlur={closeRangeCalendarRange}
                  onDismiss={closeRangeCalendarRange}
                  renderInput={() => <></>}
                  onMonthChange={setCurrentMonthHandler}
                />
              );
            }}
          />
        </LocalizationProvider>
      </Modal>
    </>
  );
};
