export const colors = {
  primary: '#7955A2',
  primaryBg: '#F6F0FE',
  primaryBgTransparent: 'rgba(115,8,232,0.1)',
  primaryText: '#6200EE',
  primaryDark: '#543B71',

  secondary: '#019592',

  yellow: '#f7d633',
  black: '#323232',
  white: '#FFFFFF',

  gray10: '#e3e3e3',
  gray20: '#e5e5e5',
  gray40: '#9f9f9f',
  gray60: '#737373',
  gray80: '#565656',

  background: '#FBFBFB',
  error: '#d32f2f',
  placeholder: '#646464',
  border: '#c2c2c2',
  boxShadow: 'rgba(34, 60, 80, 0.2)',
};
