import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { colors } from 'style';

export const ModalWrapper = styled(Box)(() => ({
  backgroundColor: colors.white,
  padding: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // margin: '0 auto',
  borderRadius: '4px',
}));
