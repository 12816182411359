import React from 'react';
import { FlexColumn, FlexRow, PaperItemWrapper, colors } from 'style';
import { Typography } from '@mui/material';
import { MarginTop } from 'components';
import { PaymentStatusEnum } from 'enum/enum';
import { cardType } from 'components/pages/success/PaymentSuccessful/PaymentSuccessful';
import { AssociatedPaymentModel } from 'models/associatedPayment.model';

type PropTypes = {
  associatedPayments: AssociatedPaymentModel[];
};

export const PaymentBlock = ({ associatedPayments }: PropTypes) => {
  const payment = associatedPayments.filter(
    (payment) =>
      payment.paymentState === PaymentStatusEnum.SUCCEEDED ||
      payment.paymentState === PaymentStatusEnum.AUTHORIZED ||
      payment.paymentState === PaymentStatusEnum.COMPLETE,
  );
  const contactNumber = payment[0].cardLast4;
  const priceTotal = payment[0].paymentAmount;
  const confirmationNumber = payment[0].paymentId.slice(-6).toUpperCase();
  const card = cardType.filter((el) => el.cardBrand === payment[0].cardBrand)[0];
  const finalcard = card ? card : cardType.filter((el) => el.cardBrand === 'stripe')[0];
  return (
    <PaperItemWrapper>
      <FlexRow justifyContent={'space-between'} alignItems={'center'}>
        <FlexColumn>
          <Typography fontSize={18} lineHeight={'24px'} fontWeight={500} color={colors.primary}>
            Payment
          </Typography>
          <MarginTop marginTop={'12'}>
            <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
              Last 4 Digits
            </Typography>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={500}>
              {contactNumber}
            </Typography>
          </MarginTop>
        </FlexColumn>
        <FlexColumn>
          <img src={finalcard.img} alt={'zaad logo'} />
        </FlexColumn>
      </FlexRow>
      <MarginTop marginTop={'22'}>
        <FlexRow justifyContent={'space-between'} alignItems={'center'}>
          <FlexColumn>
            <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
              Payment Confirmation
            </Typography>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={500}>
              {confirmationNumber}
            </Typography>
          </FlexColumn>
          <FlexColumn>
            <Typography fontSize={14} lineHeight={'24px'} fontWeight={500} color={colors.gray60}>
              Payment Total
            </Typography>
            <Typography fontSize={16} lineHeight={'24px'} fontWeight={500}>
              ${priceTotal}
            </Typography>
          </FlexColumn>
        </FlexRow>
      </MarginTop>
    </PaperItemWrapper>
  );
};
