export const routes = {
  home: '/',
  options: '/options',
  booking: '/booking',
  summary: '/summary',
  entry: '/entry',
  approval: '/approval',
  success: '/success',
  order: '/order',
  about: '/about',
  privacyTerms: '/privacy-terms',
  help: '/help',
  mytrips: '/mytrips',
  blog: '/blog',
  '404': '/404',
};
