export enum DirectionEnum {
  ONE_WAY = 'one',
  ROUND = 'round',
  MULTI = 'multi',
}

export enum TicketClassEnum {
  ECONOMY,
  BUSINESS,
  FIRST,
  ticketClassRedux,
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum StatusBookEnum {
  CREATED = 'created',
  FAILED = 'failed',
  PARTIALLY_BOOKED = 'partially_booked',
  FULLY_BOOKED = 'fully_booked',
}

export enum PaymentStatusEnum {
  ERROR = 'ERROR',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  AUTHORIZED = 'AUTHORIZED',
  SUCCEEDED = 'SUCCEEDED',
  COMPLETE = 'COMPLETE',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}

export enum TicketAgeType {
  ADULTS = 'adults',
  CHILDREN = 'children',
  INFANTS = 'infants',
}

export enum PaymentMode {
  STRIPE = 'stripe',
  WAAFI = 'waafi',
  AMADEUS = 'amadeus',
  ORCHESTRA = 'orchestra',
  MPESA = 'mpesa',
  EDAHAB = 'edahab',
}

export enum Currency {
  USD = 'USD',
  KES = 'KES',
}

export enum PaymentNetwork {
  EVC_PLUS = 'EVCplus',
  ZAAD = 'Zaad',
  SAHAL = 'Sahal',
  CREDIT_CARD = 'CreditCard',
}

export enum DrawerPage {
  NEW_SEARCH = '/',
  MY_TRIPS = 'myTrips',
  HELP = 'help',
  ABOUT = 'about',
  PRIVACY_TERMS = 'privacy-terms',
  BLOG = 'blog',
}
