import React from 'react';
import { ContainerStyled } from 'style';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FasxTravelLogo from 'assets/img/withTravelfasax.png';
import { MyTripsForm } from 'components/pages/mytrips/MyTripsForm/MyTripsForm';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const MyTripPageComponent = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <ContainerStyled>
      <Box position={'relative'} minHeight={'calc(100vh - 247px)'} display={'flex'} alignItems={'center'} justifyContent={'center'} height={1}>
        <Box maxWidth={{ sm: 720, md: 1236 }} width={1} margin={'0 auto'} paddingX={2} paddingY={{ xs: 4, sm: 6, md: 8 }}>
          <Grid container spacing={6}>
            {isMd ? (
              <Grid item container justifyContent={'center'} xs={12} md={6}>
                <Box height={1} width={1} maxWidth={500} maxHeight={200}>
                  <Box
                    component={'img'}
                    src={FasxTravelLogo}
                    width={1}
                    height={1}
                    sx={{
                      filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                    }}
                  />
                </Box>
              </Grid>
            ) : null}
            <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} md={6}>
              <MyTripsForm />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ContainerStyled>
  );
};
