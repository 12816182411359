import React from 'react';
import { TextFieldStyled } from 'components/pages/home/InputHome/styles';
import { IconButton, InputAdornment } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

interface IProps {
  startIcon: React.ReactNode;
  endIcon: React.ReactNode;
  onClickIcon?: () => void;
  hasError?: boolean;
}
export const InputHome = ({ onClickIcon, startIcon, endIcon, hasError, ...rest }: IProps & TextFieldProps) => {
  return (
    <TextFieldStyled
      hasError={hasError}
      fullWidth
      aria-readonly
      sx={{ pointerEvents: 'none' }}
      variant="filled"
      InputProps={{
        startAdornment: startIcon,
        endAdornment: (
          <InputAdornment position={'end'} sx={{ pointerEvents: 'all' }}>
            <IconButton onClick={onClickIcon}>{endIcon}</IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
