/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { FLIGHT_ORDER_DETAILS } from 'queries/flightOrderDetails';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { routes } from 'routes';
import { setFlightOrderDetails } from 'redux/slices/flightOrder/flightOrder-slice';
import { Loader } from 'components/Loader';

const validationSchema = yup.object({
  PNR: yup
    .string()
    .trim()
    .required('Confirmation Code is Required')
    .test('len', 'Confirmation codes are 6 characters, A-Z 0-9', (val) => {
      if (val === undefined) {
        return true;
      }
      return val.length === 0 || val.length === 6;
    })
    .matches(/^[aA-zZ0-9\s]+$/, 'Only A-Z and 0-9 are in confirmation codes'),
  lastName: yup.string().required('Passenger Last Name is Required'),
});

export const MyTripsForm = () => {
  const initialValues = {
    PNR: '',
    lastName: '',
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const [setData, { loading, data }] = useLazyQuery(FLIGHT_ORDER_DETAILS);

  const onSubmit = async (values: Record<string, string>) => {
    try {
      if (values) {
        await setData({
          variables: {
            PNR: values.PNR,
            lastName: values.lastName,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (loading) return <Loader />;
  if (data) {
    dispatch(setFlightOrderDetails(data.flightOrderDetails));
    history.push(routes.order);
  }
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Check On Your Trip
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Welcome back
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your confirmation code (ex: 5XYZZY)
            </Typography>
            <TextField
              {...register(`PNR`)}
              label="Code *"
              variant="outlined"
              type={'text'}
              id="PNR"
              name="PNR"
              fullWidth
              error={!!errors['PNR']}
              helperText={errors['PNR']?.message}
              autoComplete={'off'}
            />
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              marginBottom={2}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Typography variant={'subtitle2'}>Enter Last Name</Typography>
              </Box>
            </Box>
            <TextField
              {...register(`lastName`)}
              label="Last Name *"
              variant="outlined"
              type={'text'}
              id="lastName"
              name="lastName"
              fullWidth
              error={!!errors['lastName']}
              helperText={errors['lastName']?.message}
              autoComplete={'off'}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Button size={'large'} variant={'contained'} type={'submit'}>
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
