import { Box, Skeleton, styled } from '@mui/material';

export const ImageWrapper = styled(Box)<{ width: number; height: number }>`
  ${(props) => `${props.height}px`}
  ${(props) => `${props.width}px`}
  & img {
    ${(props) => `${props.height}px`}
    ${(props) => `${props.width}px`}
  }
`;

export const SkeletonAvatar = styled(Skeleton)<{ width: number; height: number }>`
  ${(props) => `${props.height}px`}
  ${(props) => `${props.width}px`}
`;

SkeletonAvatar.defaultProps = {
  variant: 'rectangular',
};
