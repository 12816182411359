import * as yup from 'yup';
import { array, object } from 'yup';
import { validation } from 'validation/validation';
import { DirectionEnum } from 'enum/enum';

const destinationAirportSchema = {
  name: validation.string,
  city: validation.string,
  cityCode: validation.string,
  airportCode: validation.string,
};

const itinerarySchema = {
  departureDate: yup.date(),
  returnDate: yup.date().nullable().notRequired(),
  destinationAirport: object().shape(destinationAirportSchema),
  originAirport: object().shape(destinationAirportSchema),
  includeDaysAfter: validation.number,
  includeDaysBefore: validation.number,
};

const itinerarySchemaRound = {
  departureDate: validation.date,
  returnDate: validation.date,
  destinationAirport: object().shape(destinationAirportSchema),
  originAirport: object().shape(destinationAirportSchema),
  includeDaysAfter: validation.number,
  includeDaysBefore: validation.number,
};

const passengerShape = {
  adults: validation.number,
  children: validation.number,
  infants: validation.number,
};

export const schema = yup.object().shape({
  ItineraryRequest: array().when('direction', {
    is: (value: string) => {
      return value === DirectionEnum.ROUND;
    },
    then: array().of(object().shape(itinerarySchemaRound)),

    otherwise: array().of(object().shape(itinerarySchema)),
  }),
  cabin: validation.number,
  direction: validation.string,
  includeAmadeus: validation.boolean,
  includeMultiticket: validation.boolean,
  includeOrchestra: validation.boolean,
  ndcProvider: validation.string,
  passengers: object().shape(passengerShape),
});
