import { Box, Paper, styled } from '@mui/material';
import { colors } from 'style';

export const ChargeBordStyled = styled(Paper)`
  background-color: ${colors.primary};
  color: ${colors.white};
`;

export const BoardHeader = styled(Box)`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 14px 34px 8px 34px;
`;

export const BoardContent = styled(Box)`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${colors.white};
  padding: 12px 0;
`;
export const Dashes = styled(Box)`
  margin: 0 8px;
`;
