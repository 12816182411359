import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { toast } from 'react-toastify';
import { onError } from '@apollo/client/link/error';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || ''; // YOUR_OWN_TRACKING_ID

export const runAppConfig = () => {
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  const browserHistory = createBrowserHistory();
  browserHistory.listen((location, action) => {
    ReactGA.pageview(location.pathname + location.search);
  });

  Sentry.init({
    dsn: process.env.REACT_APP_SANITY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });
};

export const runApolloClientConfig = () => {
  const link = createHttpLink({
    uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
    credentials: 'include',
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      toast.error('Please check your connection - contact support@fasax.com if you need further assistance');
    } else if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) => {
        toast.error(message);
      });
    }
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: errorLink.concat(link),
  });
};
