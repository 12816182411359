import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import { Toggle } from '../kit/Toggle';
import { ListItemText } from '@mui/material';
import { ListHeaderItem } from '../Drawer/styles';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { setAmadeusChecked, setOrchestraChecked } from '../../redux/slices/debuggerOptions/debuggerOptions-slice';
import { setAmadeus, setOrchestra } from '../../redux/slices/search/search-slice';
import { VersionContainer, VersionItem } from './styles';

export const Debugger = () => {
  const debuggerOptions = useAppSelector((state) => state.debugger);
  const dispatch = useDispatch();
  const [date, setDate] = useState<string>('');
  const [commit, setCommit] = useState<string>('');

  useEffect(() => {
    const buildVersion = document.querySelector('meta[build-version]');
    const commitVersion = document.querySelector('meta[name="ui-version"]');
    if (buildVersion) {
      setDate(buildVersion.getAttribute('build-version') || '');
    }
    if (commitVersion) {
      setCommit(commitVersion.getAttribute('content') || '');
    }
  }, []);

  const handlerChangeOrchestra = (value: boolean) => {
    dispatch(setOrchestraChecked(value));
    dispatch(setOrchestra(value));
  };

  const handlerChangeAmadeus = (value: boolean) => {
    dispatch(setAmadeusChecked(value));
    dispatch(setAmadeus(value));
  };

  return (
    <>
      {debuggerOptions.isDebug ? (
        <>
          <ListHeaderItem>
            <ListItemText primary={'Debug'} />
          </ListHeaderItem>
          <ListItem>
            <Toggle checked={debuggerOptions.isOrchestra} label={'Orchestra'} onChangeCallBack={handlerChangeOrchestra} />
          </ListItem>
          <ListItem>
            <Toggle checked={debuggerOptions.isAmadeus} label={'Amadeus'} onChangeCallBack={handlerChangeAmadeus} />
          </ListItem>
          <ListItem>
            <VersionContainer>
              <VersionItem>
                <strong>Date build: </strong>
                <div>{date}</div>
              </VersionItem>
              <VersionItem>
                <strong>Last commit: </strong>
                <div>{commit}</div>
              </VersionItem>
            </VersionContainer>
          </ListItem>
        </>
      ) : null}
    </>
  );
};
