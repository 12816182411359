import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, styled, Typography } from '@mui/material';
import { colors } from 'style';

export const AccordionStyled = styled(Accordion)<{ isShowMoreDetails: boolean }>`
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);

  .MuiAccordionSummary-expandIconWrapper {
    transform: none;
  }

  & svg {
    transform: ${(props) => (props.isShowMoreDetails ? 'rotate(-180deg)' : 'rotate(180deg)')};
    transition: transform 0.15s linear;
  }
`;

export const AccordionHeader = styled(AccordionSummary)`
  height: 54px;
  padding: 0 20px;
  border-bottom: 0.5px solid ${colors.gray10};
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
  padding: 0;
`;

export const ContentWrapper = styled(Card)`
  border: none;
`;

export const SelectButtonWrapper = styled(Box)`
  padding: 8px;
  border-top: 1px solid ${colors.gray10};
`;

export const SelectButton = styled(Button)`
  color: ${colors.primary};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  height: 40px;
  padding: 0;
  @media (max-width: 480px) {
    height: 30px;
  }
`;

export const TypographyAverage = styled(Typography)`
  font-size: 22px;
  line-height: 24px;
  @media (max-width: 360px) {
    font-size: 18px;
  }
`;

export const PassengersInvoice = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid ${colors.gray10};
  padding: 24px 16px;
`;
