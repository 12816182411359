import React from 'react';
import { FlexRow, colors } from 'style';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Header } from 'components';
import { routes } from 'routes';
import { resetJourneyOptionDetails } from 'redux/slices/journeyOption/journeyOption-slice';
import { setBookingPassengersValues } from 'redux/slices/booking/booking-slice';
import { setPaymentPassengerInfo } from 'redux/slices/payment/payment-slice';

export const BookingHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goBackHandler = () => {
    history.push(routes.options);
    dispatch(resetJourneyOptionDetails());
    dispatch(setPaymentPassengerInfo(null));
    dispatch(setBookingPassengersValues([]));
  };

  const headerContent = (
    <FlexRow justifyContent={'space-between'} width={'100%'} alignItems={'center'} height={'100%'} padding={'10px 0'}>
      <Typography variant="h6" component="h1" color={colors.yellow} fontWeight={500} fontSize={22} lineHeight={'22px'}>
        Passengers
      </Typography>
      <Box sx={{ alignSelf: 'end' }}>
        <IconButton onClick={goBackHandler} sx={{ color: colors.white }}>
          <EditIcon />
        </IconButton>
      </Box>
    </FlexRow>
  );
  return <Header content={headerContent} />;
};
