import { gql } from '@apollo/client';
export type RECOMMENDATION_DATES_data = {
  searchRecommendation: Array<{
    recommendationDate: string;
  }>;
};

export type RECOMMENDATION_DATES_vars = {
  departureAirport: string;
  arrivalAirport: string;
  departureDate: string;
};

export type RETURN_RECOMMENDATION_DATES_data = {
  rtSearchRecommendation: Array<{
    departureDate: string;
    returnDates: Array<string>;
  }>;
};

export type RETURN_RECOMMENDATION_DATES_vars = {
  departureAirport: string;
  arrivalAirport: string;
};

export const RECOMMENDATION_DATES = gql`
  query searchRecommendation($departureAirport: String!, $arrivalAirport: String!, $departureDate: Date!) {
    searchRecommendation(departureAirport: $departureAirport, arrivalAirport: $arrivalAirport, departureDate: $departureDate) {
      recommendationDate
    }
  }
`;
export const RETURN_RECOMMENDATION_DATES = gql`
  query rtSearchRecommendation($departureAirport: String, $arrivalAirport: String) {
    rtSearchRecommendation(departureAirport: $departureAirport, arrivalAirport: $arrivalAirport) {
      departureDate
      returnDates
    }
  }
`;
