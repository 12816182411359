import { gql } from '@apollo/client';

export const PRICE_OFFER = gql`
  query priceOffer($journeyUUID: UUID!) {
    priceOffer(journeyUUID: $journeyUUID, addedServices: []) {
      ... on JourneyOffer {
        __typename
        id
        priceTotal
        farePerAdult
        farePerChild
        farePerInfant
        source
        brandedFare
        dateChange
        departureDate
        arrivalDate
        majorityCabin
        originCity {
          name
          code
        }
        originAirport {
          code
          name
          terminal
        }
        destinationCity {
          name
          code
        }
        destinationAirport {
          code
          name
          terminal
        }
        baggageAllowance {
          checkedDescription
          checkedWeight
          checkedQuantity
          checkedWeightUnit
          carryonDescription
          carryonWeight
          carryonQuantity
          carryonWeightUnit
        }
        ticketingDetails {
          codeshare
          description
          international
          multiTicket
          offerChanged
          priceConfirmed
          offerPriceTotal
          operatingAirlines {
            code
            name
          }
          paxPricings {
            paxId
            paxType
            fareTotal
          }
          ticketOffers {
            id
            itinerariesIncluded
            ticketPriceTotal
            validatingAirline {
              name
              code
            }
          }
        }

        itineraries {
          id
          segments {
            id
            cabin
            flightinstanceId
            flightNumber
            connectionDetails {
              description
              duration
              changeOfTerminal
              changeOfAirport
              customsRequired
              overnightStay
            }
            technicalStop {
              customsRequired
              description
              duration
              stopAirport {
                name
                code
                terminal
              }
            }
            originCity {
              name
              code
            }
            originAirport {
              name
              code
              terminal
            }
            destinationCity {
              name
              code
            }
            destinationAirport {
              code
              name
              terminal
            }
            departureAt
            arrivalAt
            operatingAirline {
              name
              code
            }
            validatingAirline {
              name
              code
            }
            operatingAirline {
              name
              code
            }
            originAirport {
              name
              code
            }
            duration
            fareCode
            aircraft {
              code
              description
            }
          }
        }
      }
      ... on PricingError {
        __typename
        service
        description
      }
    }
  }
`;
