import { gql } from '@apollo/client';
import { Currency, PaymentMode, PaymentStatusEnum } from 'enum/enum';

export interface CREATE_PAYMENT_data {
  createPayment: {
    stripePaymentIntentId: string;
    stripeClientSecret: string;
    paymentState: PaymentStatusEnum;
    paymentMode: string;
    paymentId: string;
    paymentCurrency: string;
    paymentAmount: string;
    displayStringEn: string;
  };
}

export interface CREATE_PAYMENT_vars {
  accountNumber?: string;
  amountString: string;
  currencyString: Currency;
  flightOrderId: string;
  paymentMode: PaymentMode;
  agentCode?: string;
}

export const CREATE_PAYMENT = gql`
  mutation createPayment(
    $accountNumber: String
    $amountString: String!
    $currencyString: CURRENCYCHOICES!
    $flightOrderId: UUID!
    $paymentMode: PAYMENTMODECHOICES!
    $agentCode: String
  ) {
    createPayment(
      accountNumber: $accountNumber
      flightOrderId: $flightOrderId
      paymentMode: $paymentMode
      agentCode: $agentCode
      amountString: $amountString
      currencyString: $currencyString
    ) {
      stripePaymentIntentId
      stripeClientSecret
      paymentState
      paymentMode
      paymentId
      paymentCurrency
      paymentAmount
      displayStringEn
    }
  }
`;
