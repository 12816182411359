import { Card, styled } from '@mui/material';

export const ContentWrapper = styled(Card)`
  border: none;
  margin-top: 14px;
`;

export const ButtonsBlock = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
