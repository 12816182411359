import React from 'react';
import { countObjItems } from 'utils/math';
import { BookingHeader } from 'components/pages/booking/BookingHeader';
import { ContainerStyled } from 'style';
import { BookFlightForm } from 'components/pages/booking/BookFlightForm';
import { useAppSelector } from 'hooks/useAppSelector';

export const BookingPage = () => {
  const passengers = useAppSelector((state) => state.search.passengers);
  const passengersCount = countObjItems(passengers);

  return (
    <>
      <BookingHeader />
      <ContainerStyled>
        <BookFlightForm passengersCount={passengersCount} />
      </ContainerStyled>
    </>
  );
};
