import { Box, styled, Typography } from '@mui/material';
import { colors } from 'style';

export const SegmentSquareArea = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
`;

export const SegmentSquare = styled(Box)`
  width: 6px;
  height: 7px;
  border: 1px solid ${colors.black};
  background-color: ${colors.white};
`;

export const SquareSegmentWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SquareSegmentsWrapper = styled(Box)<{ marginTop?: string }>`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`;

export const SquareDurationTime = styled(Box)`
  font-size: 10px;
  color: ${colors.gray60};
  min-width: max-content;
  margin-top: 4px;
`;

export const DurationLine = styled(Box)`
  position: relative;
  border-top: 1px solid ${colors.gray40};
  width: 100%;
`;

export const ItemWrapper = styled(Box)`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 4fr 3fr;
  grid-column-gap: 8px;
  margin-bottom: 24px;
  text-align: center;
  @media (max-width: 480px) {
    grid-template-columns: 2fr 4fr 4fr 4fr;
    margin-bottom: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SliderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TypographyTime = styled(Typography)`
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
